import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Alerts from '../../../UI/Alerts/index';

export default () => (
  <Switch>
    <Route path="/ui/alertssssss" component={Alerts} />
  </Switch>
);
