import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import OrderForm from './index';

const ExistingOrderLoader = ({ t }) => {
  const { orderId } = useParams();
  return (
    <OrderForm
      t={t}
      orderId={orderId}
      items={[]}
    />
  );
};

ExistingOrderLoader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ExistingOrderLoader);
