import React from 'react';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/material_blue.css';
import { css } from '@emotion/core';
import { PropagateLoader } from 'react-spinners';

const SologoSpinner = ({ loading }) => {
  const override = css`
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
`;
  return (
    <div>
      { loading === true && (
        <div>
          <div className="spinnerBackground" />
          <div className="sweet-loading">
            <PropagateLoader
              css={override}
              size={25}
              color="#36D7B7"
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SologoSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default SologoSpinner;
