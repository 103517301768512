import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { t } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title={t('dashboard.page_title')}
            icon="chart-bars"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title={t('trademarks.dashboard_name')}
            icon="diamond"
            route="/trademarks-list"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title={t('conflicts.dashboard_name')} icon="alarm">
            <SidebarLink
              title={t('conflicts.list.sidebar_title')}
              route="/conflicts-list"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title={t('conflicts.to_verify.sidebar_title')}
              route="/conflicts-to-verify"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
          <SidebarLink
            title={t('protection_registry.sidebar_title')}
            icon="clock"
            route="/protection-registry"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title={t('orders.sidebar_title')}
            route="/orders-list"
            icon="alarm"
            onClick={this.hideSidebar}
          />
        </ul>
        <SidebarLink
          title={t('logout.dashboard_name')}
          icon="exit"
          route="/log_in?logout=true"
          onClick={this.hideSidebar}
        />
      </div>
    );
  }
}

export default withTranslation('common')(SidebarContent);
