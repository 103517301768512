import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MonitoredTrademarksStatistics from './components/MonitoredTrademarksStatistics';
import ComparisonStatistics from './components/ComparisonStatistics';
import TrademarksToCheck from './components/TrademarksToCheck';
import SynchronizedTrademarksStats from './components/SynchronizedTrademarksStats';

const Dashboard = ({ t }) => (
  <Container className="dashboard dashboard-sologo">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('dashboard.page_title')}</h3>
      </Col>
    </Row>
    <Row>
      <MonitoredTrademarksStatistics />
      <TrademarksToCheck t={t} />
      <ComparisonStatistics />
    </Row>
    <Row>
      <SynchronizedTrademarksStats />
    </Row>
  </Container>
);

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Dashboard);
