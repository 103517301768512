import React from 'react';
import { Table, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Panel from '../../../../../../shared/components/Panel';

const orderStatusToIcon = {
  REGULAR_NOT_ACTIVE: `${process.env.PUBLIC_URL}/img/simple-icons/cross-circle.svg`,
  REGULAR_ACTIVATED: `${process.env.PUBLIC_URL}/img/simple-icons/checkmark-circle.svg`,
  TRIAL_ACTIVATED: `${process.env.PUBLIC_URL}/img/simple-icons/gift.svg`,
};

function GetInvoiceBtn({
  type, orderId, translator, onDownloadInvoice,
}) {
  const btnName = `orders.list.table.actions.download_${type}_invoice`;
  return (
    <Button
      color="success"
      className="rounded icon sologo-btn-icon sologo-btn-blue"
      onClick={() => onDownloadInvoice(orderId)}
    >
      <p><span className="lnr lnr-download" />{translator(btnName)} </p>
    </Button>
  );
}

const OrdersTable = ({
  t, entries, onDelete, onDownloadInvoice, onCopy,
}) => {
  const history = useHistory();
  return (
    <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
      <div className="orders-list">
        <Button
          color="success"
          onClick={() => { history.push('/new-order'); }}
          className="rounded icon sologo-btn-icon sologo-btn-green to-the-right"
        >
          <p><span className="lnr lnr-cart" />{t('orders.list.add_new_btn')}</p>
        </Button>
        <Table responsive className="table--bordered dashboard__audience-table">
          <thead>
            <tr>
              <th>{t('orders.list.table.headers.order_number')}</th>
              <th>{t('orders.list.table.headers.status')}</th>
              <th>{t('orders.list.table.headers.creation_date')}</th>
              <th>{t('orders.list.table.headers.paid_date')}</th>
              <th>{t('orders.list.table.headers.net_price')}</th>
              <th>{t('orders.list.table.headers.gross_price')}</th>
              <th>{t('orders.list.table.headers.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              entries.map(entry => (
                <tr>
                  <td>{entry.orderId}</td>
                  <td>
                    <img
                      className="monitored-trademarks-table__status-icon"
                      src={orderStatusToIcon[`${entry.orderType}_${entry.orderStatus}`]}
                      alt=""
                    />
                    {t(`orders.list.table.order_status.${entry.orderType}_${entry.orderStatus}`)}
                  </td>
                  <td>{entry.createdDate}</td>
                  <td>{entry.paidDate}</td>
                  <td>{entry.netPrice}</td>
                  <td>{entry.grossPrice}</td>
                  <td>
                    <Button color="success" className="rounded icon sologo-btn-icon sologo-btn-green">
                      <Link
                        color="success"
                        to={entry.orderStatus === 'NOT_ACTIVE'
                          ? `/order/${entry.orderId}` : `/order-details/${entry.orderId}`
                        }
                        className="rounded icon sologo-btn-icon sologo-btn-green"
                      >
                        <p><span className="lnr lnr-arrow-right" />{t('orders.list.table.actions.details')}</p>
                      </Link>
                    </Button>
                    <GetInvoiceBtn
                      type={entry.invoiceType}
                      orderId={entry.orderId}
                      translator={t}
                      onDownloadInvoice={() => onDownloadInvoice(entry.paymentId)}
                    />
                    <Button
                      color="success"
                      className="rounded icon sologo-btn-icon sologo-btn-green"
                      onClick={() => onCopy(entry.orderId)}
                    >
                      <p><span className="lnr lnr-layers" />{t('orders.list.table.actions.make_copy')}</p>
                    </Button>
                    {(
                      entry.orderStatus === 'NOT_ACTIVE'
                      && (
                        <Button
                          color="success"
                          className="rounded icon sologo-btn-icon sologo-btn-red"
                          onClick={() => onDelete(entry.orderId)}
                        >
                          <p><span className="lnr lnr-trash" />{t('orders.list.table.actions.delete')}</p>
                        </Button>
                      )
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};

GetInvoiceBtn.propTypes = {
  type: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  translator: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
};

OrdersTable.propTypes = {
  t: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default withTranslation('common')(OrdersTable);
