import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar,
  Modal,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Select } from 'semantic-ui-react';
import renderDropZoneField from '../../../../../../shared/components/form/DropZone';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../../sologoClient.js';

const AddTrademarkModal = ({
  t, modalState, closeModal, onFormDataChange,
}) => {
  const defaultValidationState = {
    nameOrLogotypeError: '',
    nicesError: '',
  };

  const [validationErrorsState, setValidationErrorsState] = useState(defaultValidationState);

  const resetValidationState = () => {
    console.log('czyszcze');
    setValidationErrorsState(defaultValidationState);
  };

  const handleNameChange = (e) => {
    onFormDataChange({ ...modalState, formDataName: e.target.value });
  };

  const handleImgChange = (value) => {
    onFormDataChange({ ...modalState, formDataFiles: value });
  };

  const handleNicesSelection = (e, { value }) => {
    onFormDataChange({ ...modalState, formDataNices: value });
  };

  const saveTrademark = async () => {
    const { formDataName, formDataNices, formDataFiles } = modalState;
    const formData = new FormData();
    const trademarkMetadata = new Blob(
      [JSON.stringify({
        name: formDataName, nices: formDataNices,
      }, null, 2)],
      { type: 'application/json' },
    );

    formData.append('trademarkMetadata', trademarkMetadata);
    if (formDataFiles[0] != null) {
      formData.append('logotype', formDataFiles[0]);
    }

    try {
      await sologoClient.post('/api/v2/trademarks', formData);
      modalState.displayNotification('success',
        t('trademarks.list.header.add_new.modal.response.success').replace('{trademarkName}', formDataName));
      modalState.reloadTrademarksList();
      closeModal();
    } catch (e) {
      console.error(e);
      modalState.displayNotification('danger', t('trademarks.list.header.add_new.modal.response.error'));
    }
  };

  const getNicesOptions = () => {
    const arr = [];
    for (let i = 1; i <= 45; i += 1) {
      arr.push({ key: i.toString(), text: i.toString(), value: i.toString() });
    }
    return arr;
  };

  function fileIsMissing() {
    return !modalState.formDataFiles || modalState.formDataFiles.length === 0;
  }

  const isValid = () => {
    let { nicesError, nameOrLogotypeError } = validationErrorsState;
    nicesError = '';
    nameOrLogotypeError = '';
    if (!modalState.formDataNices || modalState.formDataNices.length === 0) {
      nicesError = t('trademarks.list.header.add_new.modal.validation.nices_empty');
    }
    if (!modalState.formDataName && fileIsMissing()) {
      nameOrLogotypeError = t('trademarks.list.header.add_new.modal.validation.name_and_logotype_empty');
    }
    setValidationErrorsState({ nicesError, nameOrLogotypeError });

    return !nicesError;
  };

  return (
    <Modal
      isOpen={modalState.isOpened}
      toggle={() => { closeModal(); resetValidationState(); }}
      className="sologo-modal"
      contentClassName="add-trademark-modal theme-light"
      backdrop="static"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={() => { closeModal(); resetValidationState(); }}
        />
        <img
          className="header-icon"
          src={`${process.env.PUBLIC_URL}/img/simple-icons/registered-regular.svg`}
          alt=""
        />
        <h4 className="bold-text  modal__title header-msg">
          {t('trademarks.list.header.add_new.modal.title')}
        </h4>
      </div>
      <form
        className="form form--horizontal content-fields"
        onSubmit={(e) => {
          e.preventDefault();
          if (isValid()) {
            resetValidationState();
            saveTrademark();
          }
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('trademarks.list.header.add_new.modal.trademark_name')}
          </span>
          <div className="form__form-group-field detail-field">
            <input
              name="trademarkName"
              value={modalState.formDataName}
              onChange={handleNameChange}
              type="text"
              placeholder={t('trademarks.list.header.add_new.modal.trademark_name_placeholder')}
            />
            {
              validationErrorsState.nameOrLogotypeError
              && <span className="form__form-group-error error-msg">{validationErrorsState.nameOrLogotypeError}</span>
            }
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('trademarks.list.header.add_new.modal.image')}
          </span>
          <div className="form__form-group-field detail-field">
            <Field
              name="trademarkImage"
              component={() => renderDropZoneField({
                input: {
                  name: 'logotype-img',
                  value: modalState.formDataFiles,
                  onChange: handleImgChange,
                },
                customHeight: false,
              })}
              placeholder={t('trademarks.list.header.add_new.modal.image_placeholder')}
            />
            {
              validationErrorsState.nameOrLogotypeError
              && <span className="form__form-group-error error-msg">{validationErrorsState.nameOrLogotypeError}</span>
            }
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('trademarks.list.header.add_new.modal.nices')}
          </span>
          <div className="form__form-group-field detail-field">
            <Select
              placeholder={t('trademarks.list.header.add_new.modal.nices_placeholder')}
              fluid
              multiple
              selection
              search
              onChange={handleNicesSelection}
              options={getNicesOptions()}
            />
            {
              validationErrorsState.nicesError
              && <span className="form__form-group-error error-msg">{validationErrorsState.nicesError}</span>
            }
          </div>
        </div>
        <ButtonToolbar className="footer">
          <Button
            color="primary"
            className="sologo-btn sologo-btn-green footer-btn"
            type="submit"
          >
            {t('trademarks.list.header.add_new.modal.submit_btn')}
          </Button>
        </ButtonToolbar>
      </form>
    </Modal>
  );
};

AddTrademarkModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    isOpened: PropTypes.bool.isRequired,
    displayNotification: PropTypes.func.isRequired,
    reloadTrademarksList: PropTypes.func.isRequired,
    formDataName: PropTypes.string.isRequired,
    formDataNices: PropTypes.arrayOf(PropTypes.string).isRequired,
    formDataFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'add_new_trademark_in_modal_form', // a unique identifier for this form
})(withTranslation('common')(AddTrademarkModal));
