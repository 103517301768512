import React from 'react';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import Logotype from '../../../Trademarks/TrademarksList/components/Logotype';

const OrderItemsTable = ({ t, items }) => (
  <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
    <div className="new-order-trademarks-list">
      <Table responsive className="table--bordered dashboard__audience-table">
        <thead>
          <tr>
            <th>{t('orders.details.table.headers.trademark')}</th>
            <th>{t('orders.details.table.headers.nices')}</th>
            <th>{t('orders.details.table.headers.area')}</th>
            <th>{t('orders.details.table.headers.net_price')}</th>
            <th>{t('orders.details.table.headers.gross_price')}</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map(entry => (
              <tr>
                <td>
                  <Logotype
                    logotypePath={`/api/v2/trademarks/logotypes/${entry.logotypeId}`}
                    className="dashboard__table-flag"
                  />
                  {entry.name}
                </td>
                <td>{entry.nices.join(', ')}</td>
                <td>UPRP, EUIPO, WIPO</td>
                <td>{entry.netPrice}</td>
                <td>{entry.grossPrice}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  </Panel>
);

OrderItemsTable.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default withTranslation('common')(OrderItemsTable);
