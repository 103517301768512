import React, { useEffect, useState } from 'react';
import { Table, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Panel from '../../../../../../shared/components/Panel';
import DownloadReportModal from './DownloadReportModal';
import AddTrademarkModal from './AddTrademarkModal';
import Logotype from './Logotype';
import SologoModal from '../../../shared/SologoModal';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../../sologoClient.js';

const monitoringStatusToIcon = {
  MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/checkmark-circle.svg`,
  MONITORED_TEST: `${process.env.PUBLIC_URL}/img/simple-icons/gift.svg`,
  EXPIRING: `${process.env.PUBLIC_URL}/img/simple-icons/warning-red.svg`,
  NOT_MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/cross-circle.svg`,
};

const TrademarksTable = ({ t, displayNotification }) => {
  const history = useHistory();
  const getNumOfSelectedTrademarks = (currentlySelected) => {
    let num = 0;
    currentlySelected.forEach((value) => {
      if (value) {
        num += 1;
      }
    });
    return num;
  };

  const initTrademarksTableState = {
    allSelected: false,
    indeterminate: false,
    selectionStatuses: new Map([]),
    rows: [],
  };

  const [reportModalState, setReportModalState] = useState(
    {
      isOpened: false,
      templateName: 'NAZWA KLIENTA',
      trademarkIds: [],
    },
  );

  const [trademarksTableState, setTrademarksTableState] = useState(initTrademarksTableState);
  const [deletionModalState, setDeletionModalState] = useState({
    isOpen: false,
    message: '',
  });

  const fetchTrademarks = async () => {
    try {
      const response = await sologoClient.get('/api/v2/trademarks');
      setTrademarksTableState({
        ...trademarksTableState,
        rows: response.data,
        selectionStatuses: new Map(response.data.map(({ id }) => [id, false])),
      });
    } catch (e) {
      displayNotification('danger', t('trademarks.list.table.error.fetch_list'));
    }
  };

  const openDeletionModal = ({ id, name }) => {
    setDeletionModalState({
      isOpen: true,
      message: t('trademarks.list.table.actions.delete.modal.message').replace('{name}', name),
      data: { id, name },
    });
  };

  const onDeleteConfirmation = async ({ id, name }) => {
    try {
      await sologoClient.delete(`/api/v2/trademarks/${id}`);
      displayNotification(
        'success',
        t('trademarks.list.table.success.delete_trademark').replace('{trademarkName}', name),
      );
    } catch (e) {
      displayNotification('danger', t('trademarks.list.table.error.delete_trademark'));
    }
  };

  const closeDeletionModal = () => {
    setDeletionModalState({ ...deletionModalState, isOpen: false });
  };

  const [addTrademarkModalState, setAddTrademarkModalState] = useState(
    {
      isOpened: false,
      displayNotification,
      reloadTrademarksList: fetchTrademarks,
      formDataName: '',
      formDataNices: [],
      formDataFiles: [],
    },
  );

  const onReportModalClose = () => {
    setReportModalState({ ...reportModalState, isOpened: false });
  };

  const onAddTrademarkClose = () => {
    setAddTrademarkModalState({
      ...addTrademarkModalState,
      isOpened: false,
      formDataName: '',
      formDataNices: [],
      formDataFiles: [],
    });
  };

  const onFormDataChange = (change) => {
    setAddTrademarkModalState(change);
  };

  const openAddTrademarkModal = () => {
    setAddTrademarkModalState({ ...addTrademarkModalState, isOpened: true });
  };

  const openReportModalForTrademark = (trademark) => {
    setReportModalState({ ...reportModalState, trademarkIds: [trademark.id], isOpened: true });
  };

  const getSelectedTrademarks = () => {
    const { selectionStatuses } = trademarksTableState;
    const selectedTrademarks = [];
    selectionStatuses.forEach((isSelected, trademarkId) => {
      if (isSelected) selectedTrademarks.push(trademarkId);
    });
    return selectedTrademarks;
  };

  const onHeaderCheckboxChange = (allSelected) => {
    const newSelectionStatuses = new Map();
    const { rows } = trademarksTableState;
    rows.map(trademark => newSelectionStatuses.set(trademark.id, allSelected));
    setTrademarksTableState({
      ...trademarksTableState,
      allSelected,
      indeterminate: false,
      selectionStatuses: newSelectionStatuses,
    });
  };

  const onRowChange = (changedRow, checked) => {
    const { selectionStatuses } = trademarksTableState;
    const newSelectionStatuses = new Map(selectionStatuses);
    newSelectionStatuses.set(changedRow, checked);
    const selectedRows = getNumOfSelectedTrademarks(newSelectionStatuses);
    const numberOfRows = selectionStatuses.size;
    const isIndeterminate = selectedRows > 0 && selectedRows < numberOfRows;
    const isAllSelected = selectedRows === numberOfRows;

    setTrademarksTableState({
      ...trademarksTableState,
      selectionStatuses: newSelectionStatuses,
      allSelected: isAllSelected,
      // some row was unchecked because allSelected is true
      indeterminate: isIndeterminate,
    });
  };

  const onExtendProtection = () => {
    const selectedTrademarks = getSelectedTrademarks();
    history.push({
      pathname: '/new-order',
      data: selectedTrademarks,
    });
  };

  useEffect(() => {
    fetchTrademarks();
  }, []);

  return (
    <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
      <div className="trademarks-list">
        <div className="header-buttons">
          <Button
            color="success"
            className="rounded sologo-btn sologo-btn-blue"
            onClick={openAddTrademarkModal}
          >
            {t('trademarks.list.header.add_new.btn')}
          </Button>
          <Button
            color="success"
            className={`rounded sologo-btn sologo-btn-blue to-the-right"
            ${trademarksTableState.allSelected || trademarksTableState.indeterminate
              ? ''
              : 'disabled'}`
            }
            onClick={() => onExtendProtection()}
          >
            {t('trademarks.list.header.extend_protection')}
          </Button>
        </div>
        <Table responsive className="table--bordered dashboard__audience-table">
          <thead>
            <tr>
              <th>
                <Checkbox
                  name="new_order_main_checkbox"
                  checked={trademarksTableState.allSelected}
                  onChange={(e, { checked }) => {
                    onHeaderCheckboxChange(checked);
                  }}
                  indeterminate={trademarksTableState.indeterminate}
                />
              </th>
              <th>{t('trademarks.list.table.headers.trademark')}</th>
              <th>{t('trademarks.list.table.headers.status')}</th>
              <th>{t('trademarks.list.table.headers.expiration_date')}</th>
              <th>{t('trademarks.list.table.headers.nices')}</th>
              <th>{t('trademarks.list.table.headers.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              trademarksTableState.rows.map(entry => (
                <tr key={entry.id}>
                  <td>
                    <Checkbox
                      name={entry.id}
                      checked={trademarksTableState.selectionStatuses.get(entry.id)}
                      onChange={(e, { name, checked }) => {
                        onRowChange(name, checked);
                      }}
                    />
                  </td>
                  <td>
                    <Logotype
                      logotypePath={`/api/v2/trademarks/logotypes/${entry.logotypeId}`}
                      className="dashboard__table-flag"
                    />
                    {entry.name}
                  </td>
                  <td>
                    <img
                      className="monitored-trademarks-table__status-icon"
                      src={monitoringStatusToIcon[entry.status]}
                      alt=""
                    />
                    {t(`trademarks.list.table.monitoring_status.${entry.status}`)}
                  </td>
                  <td>{entry.expirationDate}</td>
                  <td>{entry.nices.join(', ')}</td>
                  <td>
                    <Button color="success" className="rounded icon sologo-btn-icon sologo-btn-green">
                      <Link
                        color="success"
                        to={`/trademark-details/${entry.id}`}
                        className="rounded icon sologo-btn-icon sologo-btn-green"
                      >
                        <p><span className="lnr lnr-arrow-right" />{t('trademarks.list.table.actions.details')}</p>
                      </Link>
                    </Button>
                    <Button
                      onClick={() => openReportModalForTrademark(entry)}
                      color="primary"
                      className="rounded sologo-btn sologo-btn-blue"
                    >
                      {t('trademarks.list.table.actions.get_report.table_btn')}
                    </Button>
                    {(entry.status === 'NOT_MONITORED'
                      && (
                      <Button
                        color="danger"
                        className="rounded sologo-btn sologo-btn-red"
                        onClick={() => openDeletionModal(entry)}
                      >
                        {t('trademarks.list.table.actions.delete.table_btn')}
                      </Button>
                      )
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
      <SologoModal
        color="danger"
        title={t('trademarks.list.table.actions.delete.modal.title')}
        confirm={t('trademarks.list.table.actions.delete.modal.confirmation')}
        cancel={t('trademarks.list.table.actions.delete.modal.cancel')}
        btn="Danger"
        onAccept={onDeleteConfirmation}
        onClose={closeDeletionModal}
        state={deletionModalState}
      />
      <DownloadReportModal
        t={t}
        modalState={reportModalState}
        onClose={onReportModalClose}
      />
      <AddTrademarkModal
        t={t}
        modalState={addTrademarkModalState}
        closeModal={onAddTrademarkClose}
        onFormDataChange={onFormDataChange}
      />
    </Panel>
  );
};

TrademarksTable.propTypes = {
  t: PropTypes.func.isRequired,
  displayNotification: PropTypes.func.isRequired,
};

export default withTranslation('common')(TrademarksTable);
