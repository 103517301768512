/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Bar, BarChart, Cell, ResponsiveContainer,
} from 'recharts';

const analyzedTrademark = {
  number: '1354',
  chartData: [
    { name: 'Page A', pv: 25 },
    { name: 'Page B', pv: 30 },
    { name: 'Page C', pv: 55 },
    { name: 'Page D', pv: 42 },
    { name: 'Page E', pv: 85 },
    { name: 'Page F', pv: 45 },
    { name: 'Page G', pv: 21 },
    { name: 'Page H', pv: 56 },
    { name: 'Page I', pv: 68 },
    { name: 'Page J', pv: 32 },
  ],
};

class ComparedTrademarksStats extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  handleClick = (item) => {
    const index = analyzedTrademark.chartData.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { activeIndex } = this.state;
    const { t } = this.props;

    return (
      <Card>
        <CardBody className="dashboard__card-widget analyzed-trademarks">
          <Row>
            <Col md={6} sm={12}>
              <div className="card__title">
                <h5 className="bold-text">{t('trademarks.details.analyzed_trademarks_stats.title')}</h5>
              </div>
              <span className="analyzed-comment">
                {t('trademarks.details.analyzed_trademarks_stats.comment')}
              </span>
              <div className="monitored-trademarks__number">
                <h2>{analyzedTrademark.number}</h2>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="chart">
                <ResponsiveContainer height={50}>
                  <BarChart data={analyzedTrademark.chartData}>
                    <Bar dataKey="pv" onClick={this.handleClick}>
                      {
                        analyzedTrademark.chartData.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? '#4ce1b6' : '#f6da6e'}
                            key={`cell-${index}`}
                          />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(ComparedTrademarksStats);
