import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import DefaultPages from './DefaultPages';
import Maps from './Maps';
import Charts from './Charts';
import Tables from './Tables';
import Forms from './Forms';
import UI from './UI';

import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';

import Dashboard from '../../../Dashboards/Sologo/Dashboard/index';
import TrademarksList from '../../../Dashboards/Sologo/Trademarks/TrademarksList/index';
import TrademarkDetails from '../../../Dashboards/Sologo/Trademarks/TrademarkDetails/index';
import Settings from '../../../Dashboards/Sologo/Settings/index';
import OrdersList from '../../../Dashboards/Sologo/Orders/OrdersList/index';
import OrderDetails from '../../../Dashboards/Sologo/Orders/OrderDetails/index';

import ConflictsList from '../../../Dashboards/Sologo/Conflicts/ConflictsList/index';
import ConflictsToVerify from '../../../Dashboards/Sologo/Conflicts/ToVerify/index';

import ProtectionRegistry from '../../../Dashboards/Sologo/ProtectionRegistry/ProtectionList/index';

import Mail from '../../../Mail/index';
import NewOrderLoader from '../../../Dashboards/Sologo/Orders/OrderForm/NewOrderLoader';
import ExistingOrderLoader from '../../../Dashboards/Sologo/Orders/OrderForm/ExistingOrderLoader';
import PaymentBackLoader from '../../../Dashboards/Sologo/Orders/OrdersList/PaymentBackLoader';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={Dashboard} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/trademarks-list" component={TrademarksList} />
      <Route path="/trademark-details/:trademarkId" component={TrademarkDetails} />
      <Route path="/settings" component={Settings} />
      <Route path="/orders-list" component={OrdersList} />
      <Route path="/orders-list-payment-result" component={PaymentBackLoader} />
      <Route path="/new-order" component={NewOrderLoader} />
      <Route path="/order/:orderId" component={ExistingOrderLoader} />
      <Route path="/order-details/:orderId" component={OrderDetails} />
      <Route path="/conflicts-list" component={ConflictsList} />
      <Route path="/conflicts-to-verify" component={ConflictsToVerify} />
      <Route path="/protection-registry" component={ProtectionRegistry} />
      <Route path="/ui" component={UI} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/default_pages" component={DefaultPages} />
    </div>
  </div>
);
