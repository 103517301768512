import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContactProperties from './components/ContactProperties';
import InvoiceProperties from './components/InvoiceProperties';
import Notifications from './components/Notifications';
import PasswordChange from './components/PasswordChange';

const Dashboard = ({ t }) => (
  <Container className="dashboard dashboard-sologo settings">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('settings.page_title')}</h3>
      </Col>
    </Row>
    <Row>
      <ContactProperties />
      <InvoiceProperties />
    </Row>
    <Row>
      <Notifications />
      <PasswordChange />
    </Row>
  </Container>
);

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Dashboard);
