import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import OrderForm from './index';

const NewOrderLoader = ({ t }) => {
  const { data } = useLocation();
  let items = [];
  if (data != null) {
    items = data;
  }
  return (
    <OrderForm
      t={t}
      orderId={null}
      items={items}
    />
  );
};

NewOrderLoader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(NewOrderLoader);
