import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../../sologoClient.js';

const Logotype = ({ logotypePath, className }) => {
  const [logotypeState, setLogotypeState] = useState({ src: '' });
  const fetchImg = async () => {
    if (logotypePath != null && !logotypePath.endsWith('/null')) {
      const response = await sologoClient.get(
        logotypePath,
        { responseType: 'blob' },
      );

      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(response.data);
      fileReaderInstance.onload = () => {
        const base64data = fileReaderInstance.result;
        setLogotypeState({ src: base64data });
      };
    }
  };

  useEffect(() => {
    fetchImg();
  }, [logotypePath]);

  if (logotypePath != null) {
    return (
      <img
        className={className}
        src={logotypeState.src}
        alt=""
      />
    );
  }
  return null;
};

Logotype.propTypes = {
  logotypePath: PropTypes.string,
  className: PropTypes.string.isRequired,
};

Logotype.defaultProps = {
  logotypePath: null,
};

export default (Logotype);
