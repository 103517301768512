import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  Card,
  CardBody,
  Col,
} from 'reactstrap';

const SummaryForm = ({ t, formState, periodDropDownState }) => (
  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
    <Card className="panel">
      <CardBody className="panel__body new-order-summary-panel">
        <div className="panel__content">
          <div className="summary-form">
            <div className="summary-header">{t('orders.new.summary.header')}</div>
            <div className="divider" />
            <form className="form">
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.order_date')}
                </span>
                <div className="form__form-group-field field-value">
                  {formState.orderDate}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.trademarks_count')}
                </span>
                <div className="form__form-group-field field-value">
                  {formState.trademarksCount}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.monitoring_period')}
                </span>
                <div className="form__form-group-field field-value">
                  {periodDropDownState.text}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.discount')}
                </span>
                <div className="form__form-group-field field-value">
                  {formState.discount}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.net_price')}
                </span>
                <div className="form__form-group-field field-value">
                  {formState.netPrice}
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label form-field-label field-label">
                  {t('orders.new.summary.fields.gross_price')}
                </span>
                <div className="form__form-group-field field-value">
                  {formState.grossPrice}
                </div>
              </div>
            </form>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);


SummaryForm.propTypes = {
  t: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    monitoringPeriod: PropTypes.object.isRequired,
    trademarksCount: PropTypes.number.isRequired,
    orderDate: PropTypes.string.isRequired,
  }).isRequired,
  periodDropDownState: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default reduxForm({
  form: 'order_edit_summary_form', // a unique identifier for this form
})(withTranslation('common')(SummaryForm));
