import React, { PureComponent } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  constructor() {
    super();

    this.state = {
      visible: true,
    };
  }

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    const {
      color, children,
    } = this.props;

    if (visible) {
      return (
        <div className="order-list-header-messages">
          <Alert className={color} isOpen={visible}>
            <button className="close" type="button" onClick={this.onDismiss}><span className="lnr lnr-cross" /></button>
            <div className="container">
              <div className="alert__content message">
                {children}
              </div>
            </div>
          </Alert>
        </div>
      );
    }

    return null;
  }
}
