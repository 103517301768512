import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TrademarksTable from './components/TrademarksTable';
import { displayNotification } from '../../NotificationComponent';

const TrademarksList = ({ t }) => (
  <Container className="dashboard dashboard-sologo">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('trademarks.list.page_title')}</h3>
      </Col>
    </Row>
    <Row>
      <TrademarksTable displayNotification={displayNotification} />
    </Row>
  </Container>
);

TrademarksList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TrademarksList);
