import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// const data = {
//   firstName: 'Jan',
//   lastName: 'Nowak',
//   street: 'Długa 123',
//   email: 'jan.nowak@gmail.com',
// };

const ContactProperties = ({ t }) => (
  <Col xl={6} lg={12} md={12} sm={12} xs={12}>
    <div className="contact-properties">
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('settings.contact.panel_title')}</h5>
          </div>
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.contact.form.first_name')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  defaultValue="Sdfsdf"
                  disabled="true"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.contact.form.last_name')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  defaultValue="Sdfsdf"
                  disabled="true"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.contact.form.street')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  defaultValue="Sdfsdf"
                  disabled="true"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.contact.form.email')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  defaultValue="Sdfsdf"
                  disabled="true"
                />
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  </Col>
);

ContactProperties.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'contact_properties_form', // a unique identifier for this form
})(withTranslation('common')(ContactProperties));
