import React from 'react';
import { Table, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';

const Registry = ({
  t, items, onDelete, onOpenModal, onEdit,
}) => (
  <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
    <div className="trademarks-list">
      <div className="header-buttons">
        <Button
          color="success"
          className="rounded icon sologo-btn-icon sologo-btn-green to-the-right"
          onClick={onOpenModal}
        >
          <p><span className="lnr lnr-file-add" />{t('protection_registry.buttons.add_new')}</p>
        </Button>
      </div>
      <Table responsive className="table--bordered dashboard__audience-table">
        <thead>
          <tr>
            <th>{t('protection_registry.table.headers.trademark')}</th>
            <th>{t('protection_registry.table.headers.law_number')}</th>
            <th>{t('protection_registry.table.headers.protection_date')}</th>
            <th>{t('protection_registry.table.headers.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map(entry => (
              <tr>
                <td>{entry.name}</td>
                <td>{entry.lawNr}</td>
                <td>{entry.expireAt}</td>
                <td>
                  <Button
                    color="success"
                    className="rounded icon sologo-btn-icon sologo-btn-green"
                    onClick={() => onEdit(entry)}
                  >
                    <p><span className="lnr lnr-arrow-right" />{t('protection_registry.table.actions.edit')}</p>
                  </Button>
                  <Button
                    color="success"
                    className="rounded icon sologo-btn-icon sologo-btn-red"
                    onClick={() => onDelete(entry)}
                  >
                    <p><span className="lnr lnr-trash" />{t('protection_registry.table.actions.delete.table_btn')}</p>
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  </Panel>
);

Registry.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lawNr: PropTypes.string.isRequired,
    expireAt: PropTypes.string.isRequired,
  })).isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default withTranslation('common')(Registry);
