import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router';
import TrademarksTable from './components/TrademarksTable';
import MonitoringPeriod from './components/MonitoringPeriodComponent';
import SummaryForm from './components/SummaryForm';
import ActionButtons from './components/ActionButtons';
import { downloadFile } from '../../DownloadFileComponent';
import SologoSpinner from '../../shared/SologoSpinner';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import { displayNotification } from '../../NotificationComponent';

const OrderForm = ({ t, orderId, items }) => {
  const history = useHistory();
  const monitoringPeriodOptions = [
    {
      text: t('orders.new.monitoring_period.periods.MONTH'),
      value: 'MONTH',
    },
    {
      text: t('orders.new.monitoring_period.periods.HALF_YEAR'),
      value: 'HALF_YEAR',
    },
    {
      text: t('orders.new.monitoring_period.periods.YEAR'),
      value: 'YEAR',
    },
  ];

  const initTrademarksSelectionState = {
    allSelected: false,
    indeterminate: false,
    selectionStatuses: new Map([]),
  };

  const initMonitoringPeriodState = {
    value: monitoringPeriodOptions[0].value,
    text: monitoringPeriodOptions[0].text,
  };

  const initOrderSummaryFormState = {
    trademarksCount: 0,
    orderDate: moment().format('DD.MM.YYYY'),
    discount: '0 %',
    netPrice: '0 zł',
    grossPrice: '0 zł',
  };

  const getSelectedTrademarks = currentlySelected => Array.from(currentlySelected.entries())
    .filter(entry => entry[1] === true)
    .map(entry => entry[0]);

  const getNumOfSelectedTrademarks = currentlySelected => getSelectedTrademarks(currentlySelected).length;

  const [readOnly, setReadOnly] = useState(false);
  const [spinnerTurnedOn, setSpinnerTurnedOn] = useState(false);
  const [pageTitleState, setPageTitleState] = useState(t('orders.new.page_title'));
  const [trademarksTableState, setTrademarksTableState] = useState([]);
  const [trademarksSelectionState, setTrademarksSelectionState] = useState(initTrademarksSelectionState);
  const [summaryFormState, setSummaryFormState] = useState(initOrderSummaryFormState);
  const [monitoringPeriodState, setMonitoringPeriodState] = useState(initMonitoringPeriodState);
  const [formOrderId, setFormOrderId] = useState(orderId);
  const [actionButtonsVisibility, setActionButtonsVisibility] = useState(false);

  const updateSummary = (summary) => {
    const {
      orderDate, netPrice, grossPrice, discount, trademarksCount,
    } = summary;
    setSummaryFormState({
      ...summaryFormState,
      orderDate,
      netPrice,
      grossPrice,
      discount,
      trademarksCount,
    });
    setActionButtonsVisibility(trademarksCount > 0);
  };

  const fetchSummary = async (trademarkIds, period) => {
    const body = { trademarkIds, period };
    try {
      const draftResponse = await sologoClient.post('/api/v2/orders/draft', body);
      updateSummary(draftResponse.data);
    } catch (e) {
      console.log(e);
      displayNotification('danger', t('orders.new.error.creating_draft'));
    }
  };

  const onHeaderCheckboxChange = (allSelected) => {
    const newSelectionStatuses = new Map();
    trademarksTableState.map(n => newSelectionStatuses.set(n.trademarkId, allSelected));
    setTrademarksSelectionState({
      ...trademarksSelectionState,
      allSelected,
      indeterminate: false,
      selectionStatuses: newSelectionStatuses,
    });
    const numOfSelectedTrademarks = getNumOfSelectedTrademarks(newSelectionStatuses);
    setSummaryFormState({
      ...summaryFormState,
      trademarksCount: numOfSelectedTrademarks,
    });
    setActionButtonsVisibility(numOfSelectedTrademarks > 0);
    fetchSummary(
      getSelectedTrademarks(newSelectionStatuses),
      monitoringPeriodState.value,
    );
  };

  const onTrademarkSelection = (trademarkIds, statusToSet, numberOfRows) => {
    const { selectionStatuses } = trademarksSelectionState;
    const newSelectionStatuses = new Map(selectionStatuses);
    trademarkIds.forEach(trademarkId => newSelectionStatuses.set(trademarkId, statusToSet));
    const selectedRows = getNumOfSelectedTrademarks(newSelectionStatuses);
    const isIndeterminate = selectedRows > 0 && selectedRows < numberOfRows;
    const isAllSelected = selectedRows === numberOfRows;
    setTrademarksSelectionState({
      ...trademarksSelectionState,
      selectionStatuses: newSelectionStatuses,
      allSelected: isAllSelected,
      // some row was unchecked because allSelected is true
      indeterminate: isIndeterminate,
    });
    return newSelectionStatuses;
  };

  const loadTrademarks = (trademarks) => {
    setTrademarksTableState(trademarks);
  };

  const fetchPriceList = async (period) => {
    try {
      const response = await sologoClient.get(`/api/v2/orders/items/${period}`);
      return response.data;
    } catch (e) {
      displayNotification('danger', t('orders.new.error.fetching_price_list'));
      return null;
    }
  };

  const onRowChange = (changedRow, checked) => {
    const selectedStatuses = onTrademarkSelection([changedRow], checked, trademarksTableState.length);
    fetchSummary(
      getSelectedTrademarks(selectedStatuses),
      monitoringPeriodState.value,
    );
  };

  const onPeriodChange = async (event, { value }) => {
    setMonitoringPeriodState({
      value,
      text: t(`orders.new.monitoring_period.periods.${value}`),
    });
    const priceList = await fetchPriceList(value);
    loadTrademarks(priceList);
    fetchSummary(
      getSelectedTrademarks(trademarksSelectionState.selectionStatuses),
      value,
    );
  };

  const loadOrder = (orderItems, orderPeriod, orderStatus, priceList) => {
    const selectedStatuses = onTrademarkSelection(orderItems, true, priceList.length);
    fetchSummary(
      getSelectedTrademarks(selectedStatuses),
      orderPeriod,
    );
    loadTrademarks(priceList);
    setMonitoringPeriodState({
      value: orderPeriod,
      text: t(`orders.new.monitoring_period.periods.${orderPeriod}`),
    });
    if (orderStatus === 'ACTIVATED') {
      setReadOnly(true);
    }
  };

  const fetchOrderResource = async (forOrder) => {
    const response = await sologoClient.get(`/api/v2/orders/${forOrder}`);
    return response.data;
  };

  const fetchOrder = async (orderIdToFetch) => {
    try {
      const order = await fetchOrderResource(orderIdToFetch);

      if (order.orderStatus === 'ACTIVATED') {
        history.push(`/order-details/${order.orderId}`);
      }

      const priceList = await fetchPriceList(order.period);
      loadOrder(
        order.orderItems.map(it => it.trademarkId),
        order.period,
        order.orderStatus,
        priceList,
      );
    } catch (e) {
      console.log(e);
      displayNotification('danger', t('orders.new.error.fetching_price_list'));
    }
  };

  const getInvoiceForOrder = async (orderIdToUse) => {
    const order = await fetchOrderResource(orderIdToUse);
    await downloadFile(
      `/api/v2/payments/${order.paymentId}/invoice`,
      displayNotification,
      'orders.new.success.fetching_invoice',
      'orders.new.error.fetching_invoice',
      t,
    );
    history.push('/orders-list');
  };

  const createNewOrder = async (body) => {
    try {
      const response = await sologoClient.post('/api/v2/orders', body);
      const createdOrder = response.data;
      setFormOrderId(createdOrder.orderId);
      displayNotification('success', t('orders.new.success.saved'));
      return createdOrder.orderId;
    } catch (e) {
      throw new Error('orders.new.error.saving');
    }
  };

  const putOrderResource = async (orderIdToUpdate, body) => {
    try {
      await sologoClient.put(`/api/v2/orders/${orderIdToUpdate}`, body);
      displayNotification('success', t('orders.new.success.updated'));
      return orderIdToUpdate;
    } catch (e) {
      throw new Error('orders.new.error.saving');
    }
  };

  const saveOrder = async () => {
    const trademarkIds = getSelectedTrademarks(trademarksSelectionState.selectionStatuses);
    const period = monitoringPeriodState.value;
    const body = { trademarkIds, period };

    try {
      if (formOrderId != null) {
        return await putOrderResource(formOrderId, body);
      }
      return await createNewOrder(body);
    } catch (e) {
      displayNotification('danger', t(e.message));
      return null;
    }
  };

  const onSaveAndGetInvoice = async () => {
    setSpinnerTurnedOn(true);
    const savedOrderId = await saveOrder();
    await getInvoiceForOrder(savedOrderId);
    setSpinnerTurnedOn(false);
  };

  const fetchPaymentForm = async (paymentId) => {
    try {
      const response = await sologoClient.get(`/api/v2/payments/${paymentId}/paymentForm`);
      return response.data;
    } catch (e) {
      throw new Error('orders.new.error.paying');
    }
  };

  const createInput = (name, value) => {
    const input = document.createElement('INPUT');
    input.name = name;
    input.value = value;
    input.type = 'hidden';
    return input;
  };

  const createForm = (formData) => {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'POST';
    form.action = formData.paymentUrl;
    formData.items.forEach((el) => {
      form.appendChild(createInput(el.name, el.value));
    });
    return form;
  };

  const handlePaymentRequest = async () => {
    const savedOrderId = await saveOrder();
    const order = await fetchOrderResource(savedOrderId);
    try {
      const paymentFormData = await fetchPaymentForm(order.paymentId);
      const paymentForm = createForm(paymentFormData);
      paymentForm.submit();
    } catch (e) {
      displayNotification('danger', t(e.message));
    }
  };

  const initNewOrderView = async () => {
    const priceList = await fetchPriceList(monitoringPeriodState.value);
    loadTrademarks(priceList);
    fetchSummary([], monitoringPeriodState.value);
  };

  const initNewOrderWithSelectedItems = async (orderItems) => {
    const priceList = await fetchPriceList(monitoringPeriodState.value);
    loadOrder(
      orderItems,
      monitoringPeriodState.value,
      'NOT_ACTIVE',
      priceList,
    );
  };

  useEffect(() => {
    if (formOrderId != null) {
      fetchOrder(formOrderId);
      setPageTitleState(`${t('orders.edit.page_title')} ${formOrderId}`);
    } else if (items.length > 0) {
      initNewOrderWithSelectedItems(items);
    } else {
      // new order
      initNewOrderView();
    }
  }, [orderId]);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{pageTitleState}</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={8} md={8}>
          <TrademarksTable
            trademarksSelectionState={trademarksSelectionState}
            trademarks={trademarksTableState}
            onHeaderCheckboxChange={onHeaderCheckboxChange}
            onRowCheckboxChange={onRowChange}
            readOnly={readOnly}
          />
        </Col>
        <Col md={4}>
          <MonitoringPeriod
            dropdownOptions={monitoringPeriodOptions}
            selectedOption={monitoringPeriodState}
            onSelection={onPeriodChange}
            readOnly={readOnly}
          />
          <SummaryForm formState={summaryFormState} periodDropDownState={monitoringPeriodState} />
          <ActionButtons
            onSaveAndGetProforma={onSaveAndGetInvoice}
            onPayment={handlePaymentRequest}
            isVisible={actionButtonsVisibility}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <SologoSpinner loading={spinnerTurnedOn} />
    </Container>
  );
};

OrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withTranslation('common')(OrderForm);
