import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ToVerifyList from './components/ToVerifyList';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import { displayNotification } from '../../NotificationComponent';

const ToVerifyComponent = ({ t }) => {
  const [conflictsListState, setConflictsListState] = useState([]);

  const loadConflicts = async (conflictsList) => {
    setConflictsListState(conflictsList);
  };

  const fetchConflictsToVerify = async () => {
    try {
      const response = await sologoClient.get('/api/v2/conflicts?statuses=NEEDS_DECISION');
      loadConflicts(await response.data);
    } catch (e) {
      displayNotification('danger', t('conflicts.to_verify.list.error.fetching'));
    }
  };

  const updateConflictStatus = async (conflictId, status) => {
    try {
      await sologoClient.put(`/api/v2/conflicts/${conflictId}`, { status });
      displayNotification('success', t('conflicts.to_verify.list.success.status_update'));
      fetchConflictsToVerify();
    } catch (e) {
      displayNotification('danger', t('conflicts.to_verify.list.error.status_update'));
    }
  };

  const acceptConflict = async (conflictId) => {
    await updateConflictStatus(conflictId, 'CONFIRMED');
  };

  const rejectConflict = async (conflictId) => {
    await updateConflictStatus(conflictId, 'REJECTED');
  };

  useEffect(() => {
    fetchConflictsToVerify();
  }, []);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('conflicts.to_verify.page_title')}</h3>
        </Col>
      </Row>
      <Row>
        <ToVerifyList
          t={t}
          conflictsList={conflictsListState}
          acceptConflict={acceptConflict}
          rejectConflict={rejectConflict}
        />
      </Row>
    </Container>
  );
};

ToVerifyComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ToVerifyComponent);
