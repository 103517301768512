import React, { useRef } from 'react';
// eslint-disable-next-line import/extensions
import { sologoClient } from './sologoClient.js';

let myRef = null;
const DownloadFileComponent = () => {
  myRef = useRef(null);
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/anchor-has-content
    <a ref={myRef} />
  );
};

const getFile = async (url, displayNotification, successMsg, errorMsg, t) => {
  try {
    const response = await sologoClient.get(url, { responseType: 'blob' });
    const file = window.URL.createObjectURL(await response.data);
    const a = myRef.current;
    a.download = response.headers['download-file-name'];
    a.href = file;
    a.click();
    a.href = '';
    displayNotification('success', t(successMsg));
  } catch (e) {
    console.log(e);
    displayNotification('danger', t(errorMsg));
  }
};

export async function downloadFile(url, displayNotification, successMsg, errorMsg, t) {
  return getFile(url, displayNotification, successMsg, errorMsg, t);
}
export default (DownloadFileComponent);
