import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar,
  Modal,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import DateRangePicker from '../../../shared/DateRangePicker';
import { downloadFile } from '../../../DownloadFileComponent';
import { displayNotification } from '../../../NotificationComponent';
import SologoSpinner from '../../../shared/SologoSpinner';

const DownloadReportModal = ({ t, modalState, onClose }) => {
  const [calendarState, setCalendarState] = useState({ calendar: {} });
  const [spinnerTurnedOn, setSpinnerTurnedOn] = useState(false);

  const downloadReport = (trademarkId, from, to) => {
    const fromDate = moment(from).format('YYYY-MM-DD');
    const toDate = moment(to).format('YYYY-MM-DD');
    setSpinnerTurnedOn(true);
    downloadFile(
      `/api/v2/reports/${trademarkId}?from=${fromDate}&to=${toDate}`,
      displayNotification,
      'trademarks.details.compared_trademarks.success.fetching_report',
      'trademarks.details.compared_trademarks.error.fetching_report',
      t,
    ).then(() => {
      setSpinnerTurnedOn(false);
      onClose();
    });
  };

  const onCalendarValueChange = (from, to) => {
    setCalendarState({ calendar: { from, to } });
  };

  return (
    <Modal
      isOpen={modalState.isOpened}
      toggle={onClose}
      className="sologo-modal"
      contentClassName="download-report-modal theme-light"
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
        <img
          className="header-icon"
          src={`${process.env.PUBLIC_URL}/img/simple-icons/book-green.svg`}
          alt=""
        />
        <h4 className="bold-text  modal__title header-msg">
          {
            modalState.trademarkIds.length === 1
              ? t('trademarks.list.table.actions.get_report.modal.header_msg_single')
              : t('trademarks.list.table.actions.get_report.modal.header_msg_many')
                .replace('{num}', modalState.trademarkIds.length)
          }
        </h4>
      </div>
      <div className="modal__body content-msg">
        {
          t('trademarks.list.table.actions.get_report.modal.msg_content')
            .split('\n').map(item => <p>{item}</p>)
        }
      </div>
      <form
        className="form form--horizontal content-fields"
        onSubmit={(e) => {
          e.preventDefault();
          downloadReport(modalState.trademarkIds, calendarState.calendar.from, calendarState.calendar.to);
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('trademarks.list.table.actions.get_report.modal.fields.monitoring_period')}
          </span>
          <div className="form__form-group-field detail-field">
            <DateRangePicker onUpdate={onCalendarValueChange} />
          </div>
        </div>
        <ButtonToolbar className="footer">
          <Button
            color="primary"
            className="sologo-btn sologo-btn-green footer-btn"
            type="submit"
          >
            {t('trademarks.list.table.actions.get_report.modal.btns.download')}
          </Button>
        </ButtonToolbar>
      </form>
      <SologoSpinner loading={spinnerTurnedOn} />
    </Modal>
  );
};

DownloadReportModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    isOpened: PropTypes.bool.isRequired,
    templateName: PropTypes.string.isRequired,
    trademarkIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'download_report_for_single_trademark_form', // a unique identifier for this form
})(withTranslation('common')(DownloadReportModal));
