import React from 'react';
import {
  Card, CardBody, Row, Col, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const monitoringStatusToIcon = {
  MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/checkmark-circle.svg`,
  EXPIRING: `${process.env.PUBLIC_URL}/img/simple-icons/warning-red.svg`,
  NOT_MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/cross-circle.svg`,
};

const TrademarkDetails = ({ t, data, onExtendProtection }) => (
  <Card>
    <CardBody className="dashboard__card-widget trademark-metadata">
      <Row>
        <Col>
          <Row>
            <Col>
              <div className="card__title">
                <h4 className="bold-text">{t('trademarks.details.trademark_details.title')}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.trademark_name')}</div>
            </Col>
            <Col md={6} xl={9} lg={9} xs={6}>
              <div className="text">{data.name}</div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.type')}</div>
            </Col>
            <Col md={6} xl={9} lg={9} xs={6}>
              <div className="text">
                {
                  data.logotypeId != null
                    ? t('trademarks.details.trademark_details.labels.figurative_trademark')
                    : t('trademarks.details.trademark_details.labels.word_trademark')
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.status')}</div>
            </Col>
            <Col md={6} xl={9} lg={9} xs={6}>
              <div className="text">
                <img
                  className="status-icon"
                  src={monitoringStatusToIcon[data.status]}
                  alt=""
                />
                {t(`trademarks.list.table.monitoring_status.${data.status}`)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.monitoring_to')}</div>
            </Col>
            <Col md={9} xl={9} lg={9} xs={6}>
              <div className="text extend-btn-container">
                {data.expirationDate}
                <Button
                  color="success"
                  className="sologo-link-icon sologo-btn-green to-the-right extend-btn"
                  onClick={() => onExtendProtection()}
                >
                  <p> <span className="lnr lnr-calendar-full" />
                    {t('trademarks.details.trademark_details.buttons.extend_monitoring')}
                  </p>
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.monitored_nices')}</div>
            </Col>
            <Col md={6} xl={9} lg={9} xs={6}>
              <div className="text">{data.nices.join(', ')}</div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xl={3} lg={3} xs={6}>
              <div className="label">{t('trademarks.details.trademark_details.items.monitored_area')}</div>
            </Col>
            <Col md={6} xl={9} lg={9} xs={6}>
              <div className="text">{t('trademarks.details.trademark_details.labels.monitored_area')}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

TrademarkDetails.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onExtendProtection: PropTypes.func.isRequired,
};

export default withTranslation('common')(withRouter(TrademarkDetails));
