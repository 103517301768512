import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import ConflictsTable from './components/ConflictsTable';
import { displayNotification } from '../../NotificationComponent';

const ConflictsList = ({ t }) => {
  const [conflictsListState, setConflictsListState] = useState([]);

  const loadConflicts = async (conflictsList) => {
    setConflictsListState(conflictsList);
  };

  const fetchVerifiedConflicts = async () => {
    try {
      const response = await sologoClient.get('/api/v2/conflicts?statuses=REJECTED,CONFIRMED');
      console.log(`Odpowiedz dla konfliktow: ${response}`);
      loadConflicts(await response.data);
    } catch (e) {
      displayNotification('danger', t('conflicts.list.list.error.fetching'));
    }
  };

  useEffect(() => {
    fetchVerifiedConflicts();
  }, []);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('conflicts.list.page_title')}</h3>
        </Col>
      </Row>
      <Row>
        <ConflictsTable data={conflictsListState} />
      </Row>
    </Container>
  );
};

ConflictsList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ConflictsList);
