import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import ComparedTrademarksStats from './components/ComparedTrademarksStats';
import ConflictTrademarksStats from './components/ConflictTrademarksStats';
import TrademarkDetails from './components/TrademarkDetails';
import ComparedTrademarks from './components/ComparedTrademarks';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import Logotype from '../TrademarksList/components/Logotype';
import { displayNotification } from '../../NotificationComponent';

const TrademarksDetails = ({ t }) => {
  const { trademarkId } = useParams();
  const history = useHistory();

  const [trademarkDetails, setTrademarkDetails] = useState({
    nices: [],
    logotypeId: null,
  });

  const fetchDetails = async (id) => {
    try {
      const response = await sologoClient.get(`/api/v2/trademarks/${id}`);
      setTrademarkDetails(response.data);
    } catch (e) {
      displayNotification('danger', t('trademarks.details.error.fetch_details'));
    }
  };

  const onExtendProtection = () => {
    history.push({
      pathname: '/new-order',
      data: [trademarkId],
    });
  };

  useEffect(() => {
    fetchDetails(trademarkId);
  }, []);

  if (trademarkId != null) {
    return (
      <Container className="dashboard dashboard-sologo trademark-details">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{trademarkDetails.name}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={3} xl={3} lg={6} xs={6}>
            <Card>
              <CardBody className="dashboard__card-widget">
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <div className="card__title">
                          <h4 className="bold-text">{t('trademarks.details.trademark_logotype.title')}</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Logotype
                          logotypePath={`/api/v2/trademarks/logotypes/${trademarkDetails.logotypeId}`}
                          className="trademark-img"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} xl={3} lg={6} xs={6} className="d-flex flex-column justify-content-center">
            <Row className="h-100">
              <ComparedTrademarksStats />
            </Row>
            <Row className="h-100">
              <ConflictTrademarksStats />
            </Row>
          </Col>
          <Col md={6} xl={6} lg={6} xs={6}>
            <TrademarkDetails data={trademarkDetails} onExtendProtection={onExtendProtection} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ComparedTrademarks trademarkId={trademarkId} />
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
};

TrademarksDetails.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TrademarksDetails);
