/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';

const Icon = `${process.env.PUBLIC_URL}/img/simple-icons/graphs.svg`;

const ComparisonStatistics = ({ t }) => {
  const [comparedState, setComparedState] = useState({ compared: 0 });

  const fetchComparisonStats = async () => {
    const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const response = await sologoClient.get(
      `/api/v2/comparisons/compared?from=${sevenDaysAgo}&to=${today}`,
    );
    setComparedState(response.data);
  };

  useEffect(() => {
    fetchComparisonStats();
  }, []);

  return (
    <Col md={12} xl={4} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <Row>
            <Col md={8} xl={8} lg={8} xs={6}>
              <Row>
                <Col>
                  <div className="card__title">
                    <h4 className="bold-text">{t('dashboard.comparison_statistics')}</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="monitored-trademarks__number">
                    <h2>{comparedState.compared}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4} xl={4} lg={4} xs={6} className="d-flex justify-content-center">
              <div className="d-flex align-self-center">
                <img className="monitoring-statistics__icon" src={Icon} alt="" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

ComparisonStatistics.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ComparisonStatistics);
