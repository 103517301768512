import React from 'react';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import Panel from '../../../../../../shared/components/Panel';
import 'semantic-ui-css/semantic.min.css';
import Logotype from '../../../Trademarks/TrademarksList/components/Logotype';

const monitoringStatusToIcon = {
  MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/checkmark-circle.svg`,
  NOT_MONITORED: `${process.env.PUBLIC_URL}/img/simple-icons/cross-circle.svg`,
  EXPIRING: `${process.env.PUBLIC_URL}/img/simple-icons/warning-red.svg`,
};

const TrademarksTable = ({
  t,
  trademarksSelectionState,
  trademarks,
  onHeaderCheckboxChange,
  onRowCheckboxChange,
  readOnly,
}) => {
  const onRowCheckboxClick = (e, { name, checked }) => {
    onRowCheckboxChange(name, checked);
  };

  const handleHeaderCheckboxChange = (e, { checked }) => {
    onHeaderCheckboxChange(checked);
  };

  return (
    <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
      <div className="new-order-trademarks-list">
        <Table responsive className="table--bordered dashboard__audience-table">
          <thead>
            <tr>
              {(readOnly === false
                && (
                <th>
                  <Checkbox
                    name="new_order_main_checkbox"
                    checked={trademarksSelectionState.allSelected}
                    onChange={handleHeaderCheckboxChange}
                    indeterminate={trademarksSelectionState.indeterminate}
                  />
                </th>
                )
              )}
              <th>{t('orders.new.table.headers.trademark')}</th>
              <th>{t('orders.new.table.headers.status')}</th>
              <th>{t('orders.new.table.headers.monitored_to')}</th>
              <th>{t('orders.new.table.headers.nices')}</th>
              <th>{t('orders.new.table.headers.net_price')}</th>
            </tr>
          </thead>
          <tbody>
            {
              trademarks.map(entry => (
                <tr>
                  {(readOnly === false
                    && (
                    <td>
                      <Checkbox
                        name={entry.trademarkId}
                        checked={trademarksSelectionState.selectionStatuses.get(entry.trademarkId)}
                        onChange={onRowCheckboxClick}
                      />
                    </td>
                    )
                  )}
                  <td>
                    <Logotype
                      logotypePath={`/api/v2/trademarks/logotypes/${entry.logotypeId}`}
                      className="dashboard__table-flag"
                    />
                    {entry.trademarkName}
                  </td>
                  <td>
                    <img
                      className="monitored-trademarks-table__status-icon"
                      src={monitoringStatusToIcon[entry.status]}
                      alt=""
                    />
                    {t(`orders.new.table.monitoring_status.${entry.status}`)}
                  </td>
                  <td>{entry.monitoredTo}</td>
                  <td>{entry.nices}</td>
                  <td>{entry.netPrice}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};

TrademarksTable.propTypes = {
  t: PropTypes.func.isRequired,
  trademarksSelectionState: PropTypes.shape({
    allSelected: PropTypes.bool.isRequired,
    indeterminate: PropTypes.bool.isRequired,
    selectionStatuses: PropTypes.instanceOf(Map).isRequired,
  }).isRequired,
  trademarks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHeaderCheckboxChange: PropTypes.func.isRequired,
  onRowCheckboxChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default (withTranslation('common')(TrademarksTable));
