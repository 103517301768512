import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar,
  Modal,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import DatePicker from '../../../shared/DatePicker';

const NewEntryModal = ({
  t, isModalOpen, modalState, onStateUpdate, onClose, onAddEntry,
}) => {
  const defaultValidationState = {
    nameError: '',
    lawNrError: '',
  };

  const [validationErrorsState, setValidationErrorsState] = useState(defaultValidationState);

  const resetValidationState = () => {
    setValidationErrorsState(defaultValidationState);
  };

  const onNameChange = (e) => {
    onStateUpdate(e.target.value, null, null);
  };

  const onLawNrChange = (e) => {
    onStateUpdate(null, e.target.value, null);
  };

  const onCalendarValueChange = (selectedDate) => {
    onStateUpdate(null, null, selectedDate[0]);
  };

  const isValid = () => {
    let { nameError, lawNrError } = validationErrorsState;
    nameError = '';
    lawNrError = '';
    if (!modalState.name) {
      nameError = t('protection_registry.modals.validation.name_empty');
    }
    if (!modalState.lawNr) {
      lawNrError = t('protection_registry.modals.validation.law_nr_empty');
    }
    setValidationErrorsState({ nameError, lawNrError });

    return !nameError && !lawNrError;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => { onClose(); resetValidationState(); }}
      className="sologo-modal"
      contentClassName="new-protected-entry-modal theme-light"
      backdrop="static"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={() => { onClose(); resetValidationState(); }}
        />
        <img
          className="header-icon"
          src={`${process.env.PUBLIC_URL}/img/simple-icons/new-page.svg`}
          alt=""
        />
        <h4 className="bold-text  modal__title header-msg">
          {
            t('protection_registry.modals.new_entry.header')
          }
        </h4>
      </div>
      <div className="modal__body content-msg">
        {
          t('protection_registry.modals.new_entry.msg')
            .split('\n').map(item => <p>{item}</p>)
        }
      </div>
      <form
        className="form form--horizontal content-fields"
        onSubmit={(e) => {
          e.preventDefault();
          if (isValid()) {
            resetValidationState();
            onAddEntry();
          }
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('protection_registry.modals.new_entry.trademark_name')}
          </span>
          <div className="form__form-group-field detail-field">
            <input
              name="trademarkName"
              value={modalState.name}
              onChange={onNameChange}
              type="text"
              placeholder={t('protection_registry.modals.new_entry.trademark_name_placeholder')}
            />
            {
              validationErrorsState.nameError
              && <span className="form__form-group-error error-msg">{validationErrorsState.nameError}</span>
            }
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('protection_registry.modals.new_entry.law_number')}
          </span>
          <div className="form__form-group-field detail-field">
            <input
              name="lawNumber"
              value={modalState.lawNr}
              onChange={onLawNrChange}
              type="text"
              placeholder={t('protection_registry.modals.new_entry.law_number_placeholder')}
            />
            {
              validationErrorsState.lawNrError
              && <span className="form__form-group-error error-msg">{validationErrorsState.lawNrError}</span>
            }
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label details-label">
            {t('protection_registry.modals.new_entry.expiration_date')}
          </span>
          <div className="form__form-group-field detail-field">
            <DatePicker
              onUpdate={onCalendarValueChange}
              initDate={modalState.expireAt}
            />
          </div>
        </div>
        <ButtonToolbar className="footer">
          <Button
            color="primary"
            className="sologo-btn sologo-btn-green footer-btn"
            type="submit"
          >
            {
              modalState.id == null
                ? t('protection_registry.modals.new_entry.add_btn')
                : t('protection_registry.modals.new_entry.update_btn')
            }
          </Button>
        </ButtonToolbar>
      </form>
    </Modal>
  );
};

NewEntryModal.propTypes = {
  t: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalState: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    lawNr: PropTypes.string.isRequired,
    expireAt: PropTypes.object.isRequired,
  }).isRequired,
  onStateUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'adding_new_entry_to_protection_registry_form', // a unique identifier for this form
})(withTranslation('common')(NewEntryModal));
