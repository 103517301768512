import React, { useState } from 'react';
import { Table, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import ConflictDetailsModal from './ConflictDetailsModal';
import Logotype from '../../../Trademarks/TrademarksList/components/Logotype';

const conflictStatusToIcon = {
  CONFIRMED: `${process.env.PUBLIC_URL}/img/simple-icons/checkmark-circle.svg`,
  REJECTED: `${process.env.PUBLIC_URL}/img/simple-icons/cross-circle.svg`,
};

const ConflictsTable = ({ t, data }) => {
  const [conflictDetailsState, setConflictDetailsState] = useState({ isOpened: false, conflictData: {} });

  const openModal = (conflictData) => {
    setConflictDetailsState({ isOpened: true, conflictData });
  };

  const onDetailsModalClose = () => {
    setConflictDetailsState({ isOpened: false, conflictData: {} });
  };

  if (data.length > 0) {
    return (
      <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
        <div className="conflicts-list">
          <Table responsive className="table--bordered dashboard__audience-table">
            <thead>
              <tr>
                <th>{t('conflicts.list.table.headers.monitored_trademark')}</th>
                <th>{t('conflicts.list.table.headers.conflict_trademark')}</th>
                <th>{t('conflicts.list.table.headers.status')}</th>
                <th>{t('conflicts.list.table.headers.detection_date')}</th>
                <th>{t('conflicts.list.table.headers.days_to_protest')}</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map(entry => (
                  <tr>
                    <td>
                      <Logotype logotypePath={entry.protectedLogotype} className="dashboard__table-flag" />
                      {entry.protectedName}
                    </td>
                    <td>
                      <Logotype logotypePath={entry.conflictedLogotype} className="dashboard__table-flag" />
                      {entry.conflictedName}
                    </td>
                    <td>
                      <img
                        className="monitored-trademarks-table__status-icon"
                        src={conflictStatusToIcon[entry.status]}
                        alt=""
                      />
                      {t(`conflicts.list.table.conflict_status.${entry.status}`)}
                    </td>
                    <td>{entry.detectionDate}</td>
                    <td>{entry.leftDaysToProtest}</td>
                    <td>
                      <Button
                        color="success"
                        className="rounded icon sologo-btn-icon sologo-btn-green"
                        onClick={() => openModal(entry)}
                      >
                        <p><span className="lnr lnr-arrow-right" />{t('conflicts.list.table.actions.details')}</p>
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
        <ConflictDetailsModal t={t} modalState={conflictDetailsState} onClose={onDetailsModalClose} />
      </Panel>
    );
  }
  return (
    <div className="trademarks-colision">
      <div className="no-data-msg">{t('conflicts.list.no_data')}</div>
    </div>
  );
};

ConflictsTable.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    protectedId: PropTypes.string,
    protectedName: PropTypes.string,
    protectedLogotype: PropTypes.string,
    conflictedId: PropTypes.string,
    conflictedName: PropTypes.string,
    conflictedLogotype: PropTypes.string,
    status: PropTypes.string,
    detectionDate: PropTypes.string,
    leftDaysToProtest: PropTypes.string,
  })).isRequired,
};

export default withTranslation('common')(ConflictsTable);
