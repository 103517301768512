import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersTable from './components/OrdersTable';
import OrderStatusAlert from './components/OrderStatusAlert';
import { downloadFile } from '../../DownloadFileComponent';
import SologoSpinner from '../../shared/SologoSpinner';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import { displayNotification } from '../../NotificationComponent';

const OrdersList = ({ t, isPaymentSuccess, isPaymentError }) => {
  const [ordersTableState, setOrdersTableState] = useState([]);
  const [spinnerTurnedOn, setSpinnerTurnedOn] = useState(false);

  const applyPayment = (order, payment) => ({
    ...order,
    paidDate: payment.paymentDate,
    invoiceType: payment.invoiceType,
  });

  const loadOrders = (orders, payments) => {
    const paymentIdToPayment = new Map(payments.map(i => [i.id, i]));
    const ordersWitPayments = orders.map(order => applyPayment(order, paymentIdToPayment.get(order.paymentId)));
    setOrdersTableState(ordersWitPayments);
  };

  async function fetchPaymentDetails(paymentIds) {
    const response = await sologoClient.get(`/api/v2/payments?paymentId=${paymentIds.join(',')}`);
    return response.data;
  }

  const decorateWithPaymentDetailsAndLoad = async (data) => {
    const paymentIds = data.map(entry => entry.paymentId);
    const payments = await fetchPaymentDetails(paymentIds);
    loadOrders(data, payments);
  };

  const fetchOrdersList = async () => {
    try {
      setSpinnerTurnedOn(true);
      const response = await sologoClient.get('/api/v2/orders');
      const orders = await response.data;
      await decorateWithPaymentDetailsAndLoad(orders);
    } catch (e) {
      displayNotification('danger', t('orders.list.error.fetching'));
    } finally {
      setSpinnerTurnedOn(false);
    }
  };

  const deleteOrder = async (orderId) => {
    setSpinnerTurnedOn(true);
    try {
      await sologoClient.delete(`/api/v2/orders/${orderId}`);
      displayNotification('success', t('orders.list.success.deleting'));
      fetchOrdersList();
    } catch (e) {
      displayNotification('danger', t('orders.list.error.deleting'));
    } finally {
      setSpinnerTurnedOn(false);
    }
  };

  const downloadInvoice = (paymentId) => {
    setSpinnerTurnedOn(true);
    downloadFile(
      `/api/v2/payments/${paymentId}/invoice`,
      displayNotification,
      'orders.new.success.fetching_invoice',
      'orders.new.error.fetching_invoice',
      t,
    )
      .then(() => setSpinnerTurnedOn(false));
  };

  const copyOrder = async (orderId) => {
    const body = { orderId };
    setSpinnerTurnedOn(true);
    try {
      await sologoClient.post('/api/v2/orders/copy', body);
      displayNotification('success', t('orders.list.success.copying'));
      fetchOrdersList()
        .then(() => setSpinnerTurnedOn(false));
    } catch (e) {
      setSpinnerTurnedOn(false);
      displayNotification('danger', t('orders.list.error.copying'));
    }
  };

  useEffect(() => {
    fetchOrdersList();
  }, []);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('orders.list.page_title')} </h3>
        </Col>
      </Row>
      {(
        isPaymentSuccess
        && (
        <OrderStatusAlert color="success">
          {t('orders.list.paid_messages.SUCCESS')}
        </OrderStatusAlert>
        )
      )}
      {(isPaymentError
        && (
        <OrderStatusAlert color="error">
          {t('orders.list.paid_messages.ERROR')}
        </OrderStatusAlert>
        )
      )}
      <Row>
        <OrdersTable
          entries={ordersTableState}
          onDelete={deleteOrder}
          onDownloadInvoice={downloadInvoice}
          onCopy={copyOrder}
        />
      </Row>
      <SologoSpinner loading={spinnerTurnedOn} />
    </Container>
  );
};

OrdersList.propTypes = {
  t: PropTypes.func.isRequired,
  isPaymentSuccess: PropTypes.bool,
  isPaymentError: PropTypes.bool,
};

OrdersList.defaultProps = {
  isPaymentSuccess: false,
  isPaymentError: false,
};

export default withTranslation('common')(OrdersList);
