import React, { useState } from 'react';
import {
  Table, Button, Badge, Nav,
  NavItem, NavLink, TabContent,
  TabPane,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import Panel from '../../../../../../shared/components/Panel';
import DateRangePicker from '../../../shared/DateRangePicker';
import { downloadFile } from '../../../DownloadFileComponent';
// eslint-disable-next-line import/named
import { displayNotification } from '../../../NotificationComponent';
import SologoSpinner from '../../../shared/SologoSpinner';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../../sologoClient.js';
import Logotype from '../../TrademarksList/components/Logotype';

const ComparedTrademarks = ({
  t, trademarkId,
}) => {
  const [selectedTab, setSelectedTab] = useState('CONFLICTS');
  const [conflictsState, setConflictsState] = useState({ data: [] });
  const [comparisonsState, setComparisonsState] = useState({ data: [] });
  const [calendarState, setCalendarState] = useState({});
  const [spinnerTurnedOn, setSpinnerTurnedOn] = useState(false);

  const loadConflicts = async (fromDate, toDate) => {
    const response = await sologoClient.get(
      `/api/v2/conflicts/trademark/${trademarkId}?status=CONFIRMED&fromDate=${fromDate}&toDate=${toDate}`,
    );
    setConflictsState({ data: response.data });
  };

  const loadComparisons = async (fromDate, toDate) => {
    const response = await sologoClient.get(
      `/api/v2/comparisons/trademark/${trademarkId}?status=PARTIALLY_SIMILAR&fromDate=${fromDate}&toDate=${toDate}`,
    );
    setComparisonsState({ data: response.data });
  };

  const onCalendarValueChange = (from, to) => {
    setCalendarState({ from, to });
    if (from == null || to == null) {
      return;
    }
    const fromDate = moment(from).format('YYYY-MM-DD');
    const toDate = moment(to).format('YYYY-MM-DD');
    loadConflicts(fromDate, toDate);
    loadComparisons(fromDate, toDate);
  };

  const downloadReport = () => {
    const fromDate = moment(calendarState.from).format('YYYY-MM-DD');
    const toDate = moment(calendarState.to).format('YYYY-MM-DD');
    setSpinnerTurnedOn(true);
    downloadFile(
      `/api/v2/reports/${trademarkId}?from=${fromDate}&to=${toDate}`,
      displayNotification,
      'trademarks.details.compared_trademarks.success.fetching_report',
      'trademarks.details.compared_trademarks.error.fetching_report',
      t,
    ).then(() => {
      setSpinnerTurnedOn(false);
    });
  };

  return (
    <Panel
      lg={12}
      xl={12}
      md={12}
      title={t('trademarks.details.compared_trademarks.title')}
      subhead={t('trademarks.details.compared_trademarks.subtitle')}
      allowCollapse="false"
      allowRefresh="false"
    >
      <div className="trademarks-list compared-trademarks-list">
        <div className="header-buttons">
          <DateRangePicker onUpdate={onCalendarValueChange} />
          <Button
            color="success"
            onClick={downloadReport}
            disabled={calendarState.from == null || calendarState.to == null}
            className="rounded icon sologo-btn-icon sologo-btn-green to-the-right"
          >
            <p><span className="lnr lnr-download" />
              {t('trademarks.details.compared_trademarks.buttons.download_report')}
            </p>
          </Button>
        </div>
        <div className="tabs">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: selectedTab === 'CONFLICTS' })}
                  onClick={() => setSelectedTab('CONFLICTS')}
                >
                  {t('trademarks.details.compared_trademarks.tabs.conflicts')}
                  <Badge className="topbar__link-badge tab-badge conflicts-badge">
                    <span>
                      {conflictsState.data.length}
                    </span>
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: selectedTab === 'SIMILAR' })}
                  onClick={() => setSelectedTab('SIMILAR')}
                >
                  {t('trademarks.details.compared_trademarks.tabs.similar')}
                  <Badge className="topbar__link-badge tab-badge similar-badge">
                    <span>
                      {comparisonsState.data.length}
                    </span>
                  </Badge>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={selectedTab}>
              <TabPane tabId="CONFLICTS">
                <Table responsive className="table--bordered dashboard__audience-table">
                  <thead>
                    <tr>
                      <th>{t('trademarks.details.compared_trademarks.table.trademark')}</th>
                      <th>{t('trademarks.details.compared_trademarks.table.detection_date')}</th>
                      <th>{t('trademarks.details.compared_trademarks.table.protest_date')}</th>
                      <th>{t('trademarks.details.compared_trademarks.table.registration_number')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      conflictsState.data.map(entry => (
                        <tr>
                          <td>
                            <Logotype className="dashboard__table-flag" logotypePath={entry.conflictedLogotype} />
                            {entry.conflictedName}
                          </td>
                          <td>{entry.detectionDate}</td>
                          <td>{entry.protestDate}</td>
                          <td>
                            <a
                              href={entry.conflictedUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {entry.registrationNo}
                            </a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="SIMILAR">
                <Table responsive className="table--bordered dashboard__audience-table">
                  <thead>
                    <tr>
                      <th />
                      <th>{t('trademarks.details.compared_trademarks.table.trademark')}</th>
                      <th>{t('trademarks.details.compared_trademarks.table.detection_date')}</th>
                      <th>{t('trademarks.details.compared_trademarks.table.registration_number')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      comparisonsState.data.map((entry, index) => (
                        <tr>
                          <td>
                            <input type="checkbox" id={index} />
                          </td>
                          <td>
                            <Logotype className="dashboard__table-flag" logotypePath={entry.destImgPath} />
                            {entry.destTrademarkName}
                          </td>
                          <td>{entry.detectionDate}</td>
                          <td>
                            <a
                              href={entry.destTrademarkUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {entry.registrationNo}
                            </a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <SologoSpinner loading={spinnerTurnedOn} />
    </Panel>
  );
};

ComparedTrademarks.propTypes = {
  t: PropTypes.func.isRequired,
  trademarkId: PropTypes.string.isRequired,
};

export default (withTranslation('common')(ComparedTrademarks));
