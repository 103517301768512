import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Polish } from 'flatpickr/dist/l10n/pl';

const DatePicker = ({ initDate, onUpdate }) => {
  const [date, setDate] = useState(initDate != null ? initDate : new Date());

  useEffect(() => {
    console.log('powiadamiam o inicjalnym stanie kalendarza');
    onUpdate(date);
  }, []);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    onUpdate(selectedDate);
  };

  return (
    <Flatpickr
      value={date}
      onChange={handleDateChange}
      options={{
        locale: Polish,
        dateFormat: 'd-m-Y',
      }}
    />
  );
};

DatePicker.defaultProps = {
  initDate: new Date(),
  onUpdate: () => {},
};

DatePicker.propTypes = {
  initDate: PropTypes.objectOf(PropTypes.object),
  onUpdate: PropTypes.func,
};

export default (DatePicker);
