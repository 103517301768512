import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderToggleButtonField from '../../../../../shared/components/form/ToggleButton';

const Notifications = ({ t }) => (
  <Col xl={6} lg={12} md={12} sm={12} xs={12}>
    <div className="notifications">
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('settings.notifications.panel_title')}</h5>
          </div>
          <form className="form form--horizontal wizard__form">
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.notifications.form.detected_conflicts')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="email_notifications"
                  component={renderToggleButtonField}
                  defaultChecked
                />
              </div>
              <p className="preferences-description">
                {t('settings.notifications.form.detected_conflicts_description')}
              </p>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.notifications.form.check_conflicts')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="email_notifications"
                  component={renderToggleButtonField}
                  defaultChecked
                />
              </div>
              <p className="preferences-description">
                {t('settings.notifications.form.check_conflicts_description')}
              </p>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.notifications.form.cyclic_report')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="email_notifications"
                  component={renderToggleButtonField}
                  defaultChecked
                />
              </div>
              <p className="preferences-description">
                {t('settings.notifications.form.cyclic_reports_description')}
              </p>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form-field-label">
                {t('settings.notifications.form.protest_period_reminder')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="email_notifications"
                  component={renderToggleButtonField}
                  defaultChecked
                />
              </div>
              <p className="preferences-description">
                {t('settings.notifications.form.protest_period_reminder_description')}
              </p>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  </Col>
);

Notifications.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'upload_trademark_form', // a unique identifier for this form
})(withTranslation('common')(Notifications));
