import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Registry from './components/Registry';
import SologoSpinner from '../../shared/SologoSpinner';
import NewEntryModal from './components/NewEntryModal';
import SologoModal from '../../shared/SologoModal';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';
import { displayNotification } from '../../NotificationComponent';

const ProtectionList = ({ t }) => {
  const [registryItemsState, setRegistryItemsState] = useState([]);
  const [spinnerTurnedOn, setSpinnerTurnedOn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entryModalState, setEntryModalState] = useState({
    name: '',
    lawNr: '',
    expireAt: new Date(),
  });

  const [deletionModalState, setDeletionModalState] = useState({
    isOpen: false,
    message: '',
  });

  const resetModalState = () => {
    setEntryModalState({
      id: null, name: '', lawNr: '', expireAt: new Date(),
    });
  };

  const openAddNewEntryModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    resetModalState();
    setIsModalOpen(false);
  };

  const onStateUpdate = (givenName, givenLawNr, givenExpireAt) => {
    let { name, lawNr, expireAt } = entryModalState;
    if (givenName != null) {
      name = givenName;
    }
    if (givenLawNr != null) {
      lawNr = givenLawNr;
    }
    if (givenExpireAt != null) {
      expireAt = givenExpireAt;
    }
    setEntryModalState({
      ...entryModalState,
      name,
      lawNr,
      expireAt,
    });
  };

  const fetchProtections = async () => {
    try {
      setSpinnerTurnedOn(true);
      const response = await sologoClient.get('/api/v2/expiring-trademarks');
      await setRegistryItemsState(response.data);
    } catch (e) {
      displayNotification('danger', t('protection_registry.error.fetching'));
    } finally {
      setSpinnerTurnedOn(false);
    }
  };

  const createNewEntry = async (body) => {
    try {
      setSpinnerTurnedOn(true);
      await sologoClient.post('/api/v2/expiring-trademarks', body);
      displayNotification('success', t('protection_registry.success.creating'));
    } catch (e) {
      displayNotification('danger', t('protection_registry.error.creating'));
    } finally {
      setSpinnerTurnedOn(false);
    }
  };

  const updateEntry = async (id, body) => {
    try {
      setSpinnerTurnedOn(true);
      await sologoClient.put(`/api/v2/expiring-trademarks/${id}`, body);
      displayNotification('success', t('protection_registry.success.updating'));
    } catch (e) {
      displayNotification('danger', t('protection_registry.error.updating'));
    } finally {
      setSpinnerTurnedOn(false);
    }
  };

  const onModalSubmit = async () => {
    const {
      id, name, lawNr, expireAt,
    } = entryModalState;

    const body = {
      name,
      lawNr,
      expireAt: moment(expireAt).format('DD/MM/YYYY'),
    };

    if (id == null) {
      await createNewEntry(body);
    } else {
      await updateEntry(id, body);
    }

    await fetchProtections();
    closeModal();
  };

  const onEdit = ({
    id, name, lawNr, expireAt,
  }) => {
    setEntryModalState({
      id,
      name,
      lawNr,
      expireAt: moment(expireAt, 'DD/MM/YYYY').toDate(),
    });
    openAddNewEntryModal();
  };

  const onDeleteConfirmation = async ({ id }) => {
    try {
      await sologoClient.delete(`/api/v2/expiring-trademarks/${id}`);
      displayNotification('success', t('protection_registry.success.delete'));
      fetchProtections();
    } catch (e) {
      displayNotification('danger', t('protection_registry.error.delete'));
    }
  };

  const closeDeletionModal = () => {
    setDeletionModalState({ ...deletionModalState, isOpen: false });
  };

  const onDelete = async ({ id, name, lawNr }) => {
    setDeletionModalState({
      isOpen: true,
      message: t('protection_registry.table.actions.delete.modal.message')
        .replace('{name}', name)
        .replace('{lawNr}', lawNr),
      data: { id, name, lawNr },
    });
  };

  useEffect(() => {
    fetchProtections();
  }, []);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('protection_registry.page_title')}</h3>
        </Col>
      </Row>
      <Row>
        <Registry items={registryItemsState} onDelete={onDelete} onOpenModal={openAddNewEntryModal} onEdit={onEdit} />
      </Row>
      <NewEntryModal
        t={t}
        isModalOpen={isModalOpen}
        modalState={entryModalState}
        onStateUpdate={onStateUpdate}
        onClose={closeModal}
        onAddEntry={onModalSubmit}
      />
      <SologoSpinner loading={spinnerTurnedOn} />
      <SologoModal
        color="danger"
        title={t('protection_registry.table.actions.delete.modal.title')}
        confirm={t('protection_registry.table.actions.delete.modal.confirmation')}
        cancel={t('protection_registry.table.actions.delete.modal.cancel')}
        btn="Danger"
        onAccept={onDeleteConfirmation}
        onClose={closeDeletionModal}
        state={deletionModalState}
      />
    </Container>
  );
};

ProtectionList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ProtectionList);
