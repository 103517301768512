import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Logotype from '../../../Trademarks/TrademarksList/components/Logotype';

const ToVerifyEntry = ({
  t, conflictData, acceptConflict, rejectConflict,
}) => (
  <Row className="conflict-entry">
    <Col md={4} lg={4} xl={4}>
      <div>
        <Logotype logotypePath={conflictData.protectedLogotype} className="trademark-img" />
      </div>
      <p className="trademark-label">{conflictData.protectedName}</p>
    </Col>
    <Col className="vertical-line" md={1} lg={1} xl={1}>
      <div><span>VS</span></div>
    </Col>
    <Col md={4} lg={4} xl={4}>
      <div>
        <Logotype logotypePath={conflictData.conflictedLogotype} className="trademark-img" />
      </div>
      <p className="trademark-label">{conflictData.conflictedName}</p>
    </Col>
    <Col className="details-container" md={3} lg={3} xl={3}>
      <div className="details-label">{t('conflicts.to_verify.list.details')}</div>
      <div>
        <span className="details-labels">
          {t('conflicts.to_verify.list.detection_date')}:
        </span>
        <span className="details-content">
          {conflictData.detectionDate}
        </span>
      </div>
      <div>
        <span className="details-labels">
          {t('conflicts.to_verify.list.protest_days')}:
        </span>
        <span className="details-content">
          {conflictData.leftDaysToProtest}
        </span>
      </div>
      <div className="buttons-container">
        <Button className="sologo-btn-green conflict-btn" onClick={() => acceptConflict(conflictData.id)}>
          <span>{t('conflicts.to_verify.list.accept')}</span>
        </Button>
        <Button className="sologo-btn-red conflict-btn" onClick={() => rejectConflict(conflictData.id)}>
          <span>{t('conflicts.to_verify.list.cancel')}</span>
        </Button>
      </div>
    </Col>
  </Row>
);

ToVerifyEntry.propTypes = {
  t: PropTypes.func.isRequired,
  conflictData: PropTypes.shape({
    id: PropTypes.string,
    protectedId: PropTypes.string,
    protectedName: PropTypes.string,
    protectedLogotype: PropTypes.string,
    conflictedId: PropTypes.string,
    conflictedName: PropTypes.string,
    conflictedLogotype: PropTypes.string,
    status: PropTypes.string,
    detectionDate: PropTypes.string,
    leftDaysToProtest: PropTypes.string,
  }).isRequired,
  acceptConflict: PropTypes.func.isRequired,
  rejectConflict: PropTypes.func.isRequired,
};

export default withTranslation('common')(ToVerifyEntry);
