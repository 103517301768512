import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

export default class SologoModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    cancel: PropTypes.string,
    confirm: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    state: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    title: '',
    cancel: '',
    confirm: '',
    colored: false,
    header: false,
    onAccept: () => {},
    onClose: () => {},
  };

  render() {
    const {
      color, title, confirm, cancel, colored, header, onAccept, onClose, state,
    } = this.props;

    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        <Modal
          isOpen={state.isOpen}
          toggle={onClose}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {state.message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={onClose}>{cancel}</Button>{' '}
            <Button outline={colored} color={color} onClick={() => { onClose(); onAccept(state.data); }}>
              {confirm}
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}
