import React, { useEffect, useState } from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BarChart from 'recharts/lib/chart/BarChart';
import Bar from 'recharts/lib/cartesian/Bar';
import moment from 'moment';
import Panel from '../../../../../shared/components/Panel';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';

const SynchronizedTrademarksStats = ({ t }) => {
  const [statsState, setStatsState] = useState([]);

  const fetchStats = async () => {
    const monthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const response = await sologoClient.get(
      `/api/remoteTrademarks/syncStatistics?from=${monthsAgo}`,
    );
    setStatsState(response.data);
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <Panel xl={12} lg={12} md={12} title={t('dashboard.synchronized_trademarks_chart.title')} allowRefresh="false">
      <ResponsiveContainer height={300} className="dashboard__area">
        <BarChart
          data={statsState}
          margin={{
            top: 0, right: 0, left: -15, bottom: 0,
          }}
        >
          <XAxis dataKey="date" tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend />
          <Bar
            name={t('dashboard.synchronized_trademarks_chart.legend.uprp')}
            stackId="a"
            dataKey="PL"
            fill="#298970"
            fillOpacity={0.8}
          />
          <Bar
            name={t('dashboard.synchronized_trademarks_chart.legend.euipo')}
            stackId="a"
            dataKey="EUIPO"
            fill="#ACCE3D"
            fillOpacity={0.8}
          />
          <Bar
            name={t('dashboard.synchronized_trademarks_chart.legend.wipo')}
            stackId="a"
            dataKey="WIPO"
            fill="#3FA0CA"
            fillOpacity={0.8}
          />
        </BarChart>
      </ResponsiveContainer>
    </Panel>
  );
};

SynchronizedTrademarksStats.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(SynchronizedTrademarksStats);
