import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { useParams } from 'react-router';
import OrdersItemsTable from './components/OrderItemsTable';
import OrderSummary from './components/OrderSummary';
import { BasicNotification } from '../../../../../shared/components/Notification';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../sologoClient.js';

let notification;
const OrderDetails = ({ t }) => {
  const { orderId } = useParams();
  const [orderTrademarksState, setOrderTrademarksState] = useState([]);
  const [orderState, setOrderState] = useState({
    orderSummary: {},
    trademarkIds: [],
  });
  const [paymentDateState, setPaymentDateState] = useState('');

  const displayNotification = (type, msg) => {
    notification.notice({
      content: <BasicNotification
        color={type}
        message={msg}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  };

  const fetchOrderResource = async (forOrder) => {
    const response = await sologoClient.get(`/api/v2/orders/${forOrder}`);
    return response.data;
  };

  const fetchTrademarksResource = async (trademarksIds) => {
    const response = await sologoClient.get(`/api/v2/trademarks?trademarkId=${trademarksIds.join(',')}`);
    return response.data;
  };

  const fetchPayment = async (paymentId) => {
    const response = await sologoClient.get(`/api/v2/payments/${paymentId}`);
    return response.data;
  };

  const decorateWithPrice = (trademark, orderItems) => {
    const foundItem = orderItems.find(item => item.trademarkId === trademark.id);
    return {
      netPrice: foundItem.netPrice,
      grossPrice: foundItem.grossPrice,
      name: trademark.name,
      logotypeId: trademark.logotypeId,
      nices: trademark.nices,
    };
  };

  const initView = async () => {
    try {
      const order = await fetchOrderResource(orderId);
      const trademarksIds = order.orderItems.map(it => it.trademarkId);
      const trademarks = await fetchTrademarksResource(trademarksIds);
      const payment = await fetchPayment(order.paymentId);
      setOrderState(order);
      const trademarksWithPrices = trademarks.map(trademark => decorateWithPrice(trademark, order.orderItems));
      setOrderTrademarksState(trademarksWithPrices);
      setPaymentDateState(payment.paymentDate);
    } catch (e) {
      displayNotification('danger', t('orders.details.error.loading_order'));
    }
  };

  useEffect(() => {
    NotificationSystem.newInstance({}, (n) => {
      notification = n;
    });
    initView();
  }, []);

  return (
    <Container className="dashboard dashboard-sologo">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('orders.details.page_title')}{orderId}</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={8} md={8}>
          <OrdersItemsTable items={orderTrademarksState} />
        </Col>
        <Col md={4}>
          <OrderSummary
            orderSummaryData={orderState.orderSummary}
            orderStatus={`${orderState.type}_${orderState.orderStatus}`}
            period={orderState.period}
            paymentDate={paymentDateState}
          />
        </Col>
      </Row>
    </Container>
  );
};

OrderDetails.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(OrderDetails);
