import axios from 'axios';

let isRefreshing = false;
let refreshSubscribers = [];
let csrfToken = '';
const cancelSource = axios.CancelToken.source();

export const sologoClient = axios.create({
  baseURL: process.env.REACT_APP_SOLOGO_URL,
});

export const updateCsrfToken = (token) => {
  csrfToken = token;
};

sologoClient.defaults.headers.post['Content-Type'] = 'application/json';

const subscribeTokenRefresh = (requestToRetry) => {
  refreshSubscribers.push(requestToRetry);
};

const onRefreshedToken = (token) => {
  refreshSubscribers.map(requestToRetry => requestToRetry(token));
};

// eslint-disable-next-line consistent-return
const refreshToken = async () => {
  const body = { token: localStorage.getItem('token'), refreshToken: localStorage.getItem('refreshToken') };
  try {
    const response = await sologoClient({
      url: '/api/v2/authentication/refresh',
      method: 'post',
      data: body,
    });
    return response.data;
  } catch (e) {
    console.log('refresh error');
    isRefreshing = false;
    refreshSubscribers = [];
    cancelSource.cancel('Session expired');
    window.location.href = '/log_in?expired=true';
  }
};

sologoClient.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.cancelToken = cancelSource.token;
  if (config.url.indexOf('/api/v2/authentication/token') > -1
    || config.url.indexOf('/api/v2/authentication/refresh') > -1) {
    return config;
  }
  const token = localStorage.getItem('token');
  if (token && token !== '') {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    // eslint-disable-next-line no-param-reassign
    config.headers.Accept = 'application/json';
    // eslint-disable-next-line no-param-reassign
    config.headers['X-CSRF-Token'] = csrfToken;
  }
  return config;
});

sologoClient.interceptors.response.use(response => response, (error) => {
  const { config, response } = error;
  if (response == null) {
    window.location.href = '/log_in?expired=true';
  }
  const { status } = response;
  const originalRequest = config;

  console.log(`Axios response ze statusem: ${status} ${isRefreshing}`);

  if (status === 401
    && config.url.indexOf('/api/v2/authentication/refresh') === -1
    && config.url.indexOf('/api/v2/authentication/token') === -1
  ) {
    if (!isRefreshing) {
      isRefreshing = true;
      refreshSubscribers = [];
      refreshToken()
        .then((refreshedTokens) => {
          localStorage.setItem('token', refreshedTokens.token);
          localStorage.setItem('refreshToken', refreshedTokens.refreshToken);
          isRefreshing = false;
          onRefreshedToken(refreshedTokens.token);
        });
    }

    const retryOrigReq = new Promise((resolve) => {
      subscribeTokenRefresh((refreshedToken) => {
        console.log(`Ponawiam z tokenem: ${refreshedToken}`);
        originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
        resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }
  return Promise.reject(error);
});
