import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Polish } from 'flatpickr/dist/l10n/pl';

const DateRangePicker = ({ initFrom, initTo, onUpdate }) => {
  const [range, setRange] = useState({
    from: initFrom,
    to: initTo,
  });

  useEffect(() => {
    console.log('powiadamiam o inicjalnym stanie kalendarza');
    const { from, to } = range;
    onUpdate(from, to);
  }, []);

  const onChange = (values) => {
    const [from, to] = values;
    onUpdate(from, to);
    setRange({ from, to });
  };

  return (
    <Flatpickr
      options={{
        mode: 'range',
        defaultDate: [range.from, range.to],
        locale: Polish,
        dateFormat: 'd-m-Y',
      }}
      onChange={onChange}
    />
  );
};

DateRangePicker.propTypes = {
  initFrom: PropTypes.objectOf(PropTypes.object),
  initTo: PropTypes.objectOf(PropTypes.object),
  onUpdate: PropTypes.func,
};

DateRangePicker.defaultProps = {
  initFrom: new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate(),
  ),
  initTo: new Date(),
  onUpdate: () => {},
};

export default (DateRangePicker);
