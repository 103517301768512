import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../../../shared/components/Panel';
import ToVerifyEntry from './ToVerifyEntry';

const ToVerifyList = ({
  t, conflictsList, acceptConflict, rejectConflict,
}) => {
  if (conflictsList.length > 0) {
    return (
      <Panel lg={12} xl={12} md={12} title="" allowCollapse="false" allowRefresh="false">
        <div className="conflicts-to-verify">
          <Container>
            {
              conflictsList.map(entry => (
                <ToVerifyEntry
                  t={t}
                  conflictData={entry}
                  acceptConflict={acceptConflict}
                  rejectConflict={rejectConflict}
                />
              ))
            }
          </Container>
        </div>
      </Panel>
    );
  }
  return (
    <div className="trademarks-colision">
      <div className="no-data-msg">{t('conflicts.to_verify.list.no_data')}</div>
    </div>
  );
};


ToVerifyList.propTypes = {
  t: PropTypes.func.isRequired,
  conflictsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    protectedId: PropTypes.string,
    protectedName: PropTypes.string,
    protectedLogotype: PropTypes.string,
    conflictedId: PropTypes.string,
    conflictedName: PropTypes.string,
    conflictedLogotype: PropTypes.string,
    status: PropTypes.string,
    detectionDate: PropTypes.string,
    leftDaysToProtest: PropTypes.string,
  })).isRequired,
  acceptConflict: PropTypes.func.isRequired,
  rejectConflict: PropTypes.func.isRequired,
};
export default withTranslation('common')(ToVerifyList);
