import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Modal, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Logotype from '../../../Trademarks/TrademarksList/components/Logotype';

const ConflictsDetailsModal = ({ t, modalState, onClose }) => {
  const { isOpened, conflictData } = modalState;

  return (
    <Modal
      isOpen={isOpened}
      toggle={onClose}
      className="sologo-modal"
      contentClassName="add-trademark-modal theme-light"
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
        <img
          className="header-icon"
          src={`${process.env.PUBLIC_URL}/img/simple-icons/registered-regular.svg`}
          alt=""
        />
        <h4 className="bold-text  modal__title header-msg">
          {t('conflicts.list.modal.details.title')}
        </h4>
      </div>
      <div className="conflicts-to-verify conflict-details-modal">
        <Container>
          <Row className="conflict-img">
            <Col md={12} lg={12} xl={12}>
              <div>
                <Logotype className="trademark-img" logotypePath={conflictData.protectedLogotype} />
              </div>
              <p className="trademark-label">{conflictData.protectedName}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="vs-mark"><span>VS</span></div>
            </Col>
          </Row>
          <Row className="conflict-img">
            <Col md={12} lg={12} xl={12}>
              <div>
                <Logotype className="trademark-img" logotypePath={conflictData.conflictedLogotype} />
              </div>
              <p className="trademark-label">{conflictData.conflictedName}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <div className="buttons-container">
                <Button className="sologo-btn-green" onClick={onClose}>
                  <span>{t('conflicts.list.modal.details.btn.close')}</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Modal>
  );
};

ConflictsDetailsModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    isOpened: PropTypes.bool.isRequired,
    conflictData: PropTypes.shape(PropTypes.string).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default (withTranslation('common')(ConflictsDetailsModal));
