import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import OrdersList from './index';

const PaymentBackLoader = ({ t }) => {
  const { search } = useLocation();
  return (
    <OrdersList
      t={t}
      isPaymentSuccess={search == null || search === ''}
      isPaymentError={search != null && search === '?error=501'}
    />
  );
};

PaymentBackLoader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PaymentBackLoader);
