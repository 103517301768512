/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const data = { number: '32' };

const Icon = `${process.env.PUBLIC_URL}/img/simple-icons/warning-green.svg`;

class ConflictTrademarksStats extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { t } = this.props;

    return (
      <Card>
        <CardBody className="dashboard__card-widget conflict-trademarks">
          <Row>
            <Col md={6} sm={12}>
              <div className="card__title">
                <h5 className="bold-text">{t('trademarks.details.conflict_trademarks.title')}</h5>
              </div>
              <div className="monitored-trademarks__number">
                <h2>{data.number}</h2>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <img className="monitoring-statistics__icon" src={Icon} alt="" />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(ConflictTrademarksStats);
