import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import LogInForm from './components/LogInForm';
// eslint-disable-next-line import/extensions
import { sologoClient, updateCsrfToken } from '../../Dashboards/Sologo/sologoClient.js';

const sologoLogo = `${process.env.PUBLIC_URL}/img/sologo_logo_g2.png`;

const FormMsg = ({ t, msgLabel, type }) => {
  if (msgLabel !== '') {
    return (<div className={`logout-msg-${type}`}>{t(msgLabel)}</div>);
  }
  return null;
};

const LogIn = ({ t }) => {
  const logout = useLocation();
  const history = useHistory();
  const [formState, setFormState] = useState({ additionalMsg: '', msgType: '' });

  const onSubmit = async (e, email, password) => {
    e.preventDefault();

    const body = { username: email, password };
    try {
      const response = await sologoClient.post('/api/v2/authentication/token', body);
      const tokens = response.data;
      localStorage.setItem('token', tokens.token);
      localStorage.setItem('refreshToken', tokens.refreshToken);
      updateCsrfToken(Cookies.get('XSRF-TOKEN'));
      history.push('/dashboard');
    } catch (err) {
      setFormState({ additionalMsg: 'login.invalid_credentials_msg', msgType: 'error' });
    }
  };

  useEffect(() => {
    if (logout.search.indexOf('logout=true') > -1) {
      setFormState({ additionalMsg: 'login.logout_msg', msgType: 'success' });
      localStorage.setItem('token', null);
      localStorage.setItem('refreshToken', null);
    } else if (logout.search.indexOf('expired=true') > -1) {
      setFormState({ additionalMsg: 'login.expired_msg', msgType: 'error' });
    }
  }, []);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="sologo-logo-container">
            <img className="sologo-logo" src={sologoLogo} alt="" />
          </div>
          <div>
            <FormMsg t={t} msgLabel={formState.additionalMsg} type={formState.msgType} />
          </div>
          <LogInForm handleSubmit={onSubmit} t={t} />
        </div>
      </div>
    </div>
  );
};

FormMsg.propTypes = {
  t: PropTypes.func.isRequired,
  msgLabel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

LogIn.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(LogIn));
