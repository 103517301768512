import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const LogInForm = ({ t, handleSubmit }) => {
  const [formState, setFormState] = useState({
    showPassword: false, email: '', password: '',
  });

  const onSubmit = (e) => {
    setFormState({ showPassword: false, email: '', password: '' });
    handleSubmit(e, formState.email, formState.password);
  };

  return (
    <form className="form" onSubmit={e => onSubmit(e)}>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('login.field.email')}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <input
            name="email"
            component="input"
            type="text"
            placeholder="Email"
            value={formState.email}
            onChange={e => setFormState({ ...formState, email: e.target.value })}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          {t('login.field.password')}
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            name="password"
            component="input"
            type={formState.showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={formState.password}
            onChange={e => setFormState({ ...formState, password: e.target.value })}
          />
          <button
            type="button"
            className={`form__form-group-button${formState.showPassword ? ' active' : ''}`}
            onClick={() => setFormState({ ...formState, showPassword: !formState.showPassword })}
          ><EyeIcon />
          </button>
        </div>
        <div className="account__forgot-password">
          <a href="https://panel.sologosystem.pl/passwordResetRequest" target="_blank" rel="noopener noreferrer">
            {t('login.field.forgot_password')}
          </a>
        </div>
      </div>
      <div className="account__btns">
        <Button
          className="btn sologo-btn-green account__btn"
          type="submit"
        >{t('login.btn.log_in')}
        </Button>
        <a
          className="text-link text-center"
          href="https://sologosystem.pl/dlaczego-warto/#Przetestuj"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login.btn.try_it')}
        </a>
      </div>
    </form>
  );
};

LogInForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'log_in_form', // a unique identifier for this form
})(LogInForm);
