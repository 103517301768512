import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';

const ActionButtons = ({
  t, onSaveAndGetProforma, onPayment, isVisible, readOnly,
}) => (
  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
    { readOnly === false && isVisible
      && (
        <Card className="panel">
          <CardBody className="panel__body new-order-action-buttons">
            <Row>
              <Button className="sologo-btn-light-green action-btn" type="button" onClick={onPayment}>
                {t('orders.new.summary.buttons.pay')}
              </Button>
            </Row>
            <Row>
              <Button className="sologo-btn-blue action-btn" type="button" onClick={onSaveAndGetProforma}>
                {t('orders.new.summary.buttons.save_and_get_proforma')}
              </Button>
            </Row>
          </CardBody>
        </Card>
      )
    }
  </Col>
);

ActionButtons.propTypes = {
  t: PropTypes.func.isRequired,
  onSaveAndGetProforma: PropTypes.func.isRequired,
  onPayment: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default withTranslation('common')(ActionButtons);
