import React, { useEffect } from 'react';
// eslint-disable-next-line import/extensions
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../shared/components/Notification';

let notification = null;
const NotificationComponent = () => {
  useEffect(() => {
    NotificationSystem.newInstance({}, (n) => {
      notification = n;
    });
  }, []);

  useEffect(() => () => { notification.destroy(); }, []);

  return null;
};

const showNotification = (type, msg) => {
  notification.notice({
    content: <BasicNotification
      color={type}
      message={msg}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};

export async function displayNotification(type, msg) {
  return showNotification(type, msg);
}

export default (NotificationComponent);
