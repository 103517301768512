import React, { useEffect, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TopbarMenuLink from './TopbarMenuLink';
// eslint-disable-next-line import/extensions
import { sologoClient } from '../../Dashboards/Sologo/sologoClient.js';

const TopbarProfile = ({ t }) => {
  const [profileState, setProfileState] = useState({ collapse: false, username: '' });

  const toggle = () => {
    setProfileState({ ...profileState, collapse: !profileState.collapse });
  };

  const fetchUserData = async () => {
    const response = await sologoClient.get('/api/users/me');
    const respJson = await response.data;
    setProfileState({ ...profileState, username: respJson.username });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggle}>
        <p className="topbar__avatar-name">{profileState.username}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {profileState.collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse isOpen={profileState.collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title={t('logout.dashboard_name')} icon="exit" path="/log_in?logout=true" />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarProfile);
