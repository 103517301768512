import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Panel from '../../../../../../shared/components/Panel';
import 'semantic-ui-css/semantic.min.css';

const MonitoringPeriodComponent = ({
  t,
  dropdownOptions,
  selectedOption,
  onSelection,
  readOnly,
}) => (
  <Panel
    lg={12}
    xl={12}
    md={12}
    title={t('orders.new.monitoring_period.header')}
    allowCollapse="false"
    allowRefresh="false"
  >
    <div className="new-order-monitoring-component">
      <Dropdown
        value={selectedOption.value}
        className="new-order-dropdown"
        selection
        disabled={readOnly}
        options={dropdownOptions}
        onChange={onSelection}
      />
    </div>
  </Panel>
);

MonitoringPeriodComponent.propTypes = {
  t: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOption: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelection: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default withTranslation('common')(MonitoringPeriodComponent);
